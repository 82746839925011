import React from 'react'
import { Box } from '@material-ui/core'
import {
  Edit,
  FormWithRedirect,
  useDataProvider,
  // useNotify,
  useGetIdentity,
  // useRedirect,
} from 'react-admin'
import Confirm from '../components/Confirm'
import FormData from './components/EditFormData'

const SourcingRequestEdit = (props) => {
  return (
    <Edit
      {...props}
      actions={null}
      component="div"
      id={props.match.params.id}
      mutationMode="pessimistic"
    >
      <SourcingRequestEditForm id={props.match.params.id} />
    </Edit>
  )
}

const SourcingRequestEditForm = (props) => {
  const dataProvider = useDataProvider()
  const { identity } = useGetIdentity()
  const { record } = props

  // console.log('SRDATA', record)

  //   const packagingCriteria = data.packaging_criteria.map()

  const packagingCriteria = []
  //   console.log(data.packaging_criteria)

  const uniquePackagingCriteria = []
  record.packaging_criteria?.map((criteria) => {
    if (uniquePackagingCriteria.indexOf(criteria.criteria) === -1) {
      uniquePackagingCriteria.push(criteria.criteria)
    }
  })

  //   console.log(uniquePackagingCriteria)

  uniquePackagingCriteria.map((criteria) => {
    packagingCriteria?.push({
      criteria: criteria,
      value: record.packaging_criteria
        .filter((qcriteria) => qcriteria.criteria === criteria)
        .map((item) => item.value),
    })
  })

  // console.log(productCriteria)

  const productCriteria = []
  // console.log(record.product_criteria)

  const uniqueProductCriteria = []
  record.product_criteria?.map((criteria) => {
    if (uniqueProductCriteria.indexOf(criteria.criteria) === -1) {
      uniqueProductCriteria.push(criteria.criteria)
    }
  })

  // console.log(uniqueProductCriteria)

  const rgbToHex = (r, g, b) => {
    return '#' + ((1 << 24) | (r << 16) | (g << 8) | b).toString(16).slice(1)
  }

  uniqueProductCriteria.map((criteria) => {
    if (
      criteria == 'Colour' ||
      criteria == 'Color' ||
      criteria == 'colour' ||
      criteria == 'color'
    ) {
      record.product_criteria
        .filter((qcriteria) => qcriteria.criteria === criteria)
        .map((value) => {
          // console.log(value)
          const jsonValue = JSON.parse(value.value)

          switch (jsonValue.type) {
            case 'HEX':
              productCriteria?.push({
                id: value.id,
                criteria: 'Color',
                format: 'HEX',
                value: `#${jsonValue.HEX}`,
              })
              break
            case 'PANTONE':
              productCriteria?.push({
                id: value.id,
                criteria: 'Color',
                format: 'PANTONE',
                value: jsonValue.PANTONE,
              })
              break
            case 'RGB':
              productCriteria?.push({
                id: value.id,
                criteria: 'Color',
                format: 'RGB',
                value: rgbToHex(jsonValue.R, jsonValue.G, jsonValue.B),
              })
              break
            case 'CMYK':
              productCriteria?.push({
                id: value.id,
                criteria: 'Color',
                format: 'CMYK',
                value: [jsonValue.C, jsonValue.M, jsonValue.Y, jsonValue.K],
              })
              break
            default:
              productCriteria?.push({
                id: value.id,
                criteria: 'Color',
                value: 'Unknown colour type',
              })
          }
        })
    } else {
      productCriteria?.push({
        criteria: criteria,
        value: record.product_criteria
          .filter((qcriteria) => qcriteria.criteria === criteria)
          .map((item) => item.value),
      })
    }
  })

  // console.log(productCriteria)

  return (
    <FormWithRedirect
      {...props}
      validate={(values) => {
        // console.log(values)
        const errors = {}
        values.products?.length < 1 ? (errors.products = 'Required at least one product') : null
        !values.feedback ? (errors.feedback = 'Required feedback') : null
        // values.status !== 'IN_PROCESS' ? (errors.status = 'Request status not valid') : null
        return errors
      }}
      initialValues={{
        targetPrice: record.target_price / 100,
        oldProductCriteria: record.product_criteria,
        oldPackagingCriteria: record.packaging_criteria,
        productCriteria,
        packagingCriteria,
        designs: [],
        // feedback: record.feedbacks?.length > 0 ? record.feedbacks[0].content : '',
        productImprintStyle:
          record.product_imprints?.length > 0 ? record.product_imprints[0].description : null,
        packagingImprintStyle:
          record.packaging_imprints?.length > 0 ? record.packaging_imprints[0].description : null,
        products: record.products,
      }}
      render={(formProps) => (
        <Box p="0px 20px">
          <FormData
            title="Edit Product"
            sourcing_request_id={record.id}
            isManager={identity?.isManager}
            formProps={formProps}
          />
          <Box display="flex" justifyContent="center" alignItems="center" mt="0px">
            {!record.payment_confirmed && (
              <Confirm
                redirect="/sourcingRequests"
                record={formProps.record}
                basePath={formProps.basePath}
                label="CONFIRM PAYMENT"
                undoable
                invalid={false}
                ignoreInvalid
                handleSubmit={async () => {
                  await dataProvider.update('sourcing_request', {
                    data: { id: record.id, payment_confirmed: true },
                  })
                  // await handleSave({ type: 'PAYMENT', submission })
                }}
                saving={formProps.saving}
                disabled={formProps.saving}
                resource="sourcing_request"
              />
            )}
            <Confirm
              redirect="/sourcingRequests"
              // redirect={null}
              record={formProps.record}
              basePath={formProps.basePath}
              label="SUBMIT TO CLIENT"
              undoable
              invalid={formProps.invalid}
              handleSubmit={async () => {
                await dataProvider.update('sourcing_request', {
                  data: { id: record.id, status: 'FEEDBACK' },
                })
                // await handleSave({ type: 'SUBMIT', submission })
              }}
              saving={formProps.saving}
              disabled={formProps.saving || !identity?.isManager}
              resource="offer"
            />
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <span style={{ fontSize: '12px', fontStyle: 'italic' }}>
              Upon submission of your report, remember to add an event in the activity feed.
            </span>
          </Box>
        </Box>
      )}
    />
  )
}

export default SourcingRequestEdit
