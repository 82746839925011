import React from 'react'
import { Box, Button, Tooltip } from '@material-ui/core'
import { useForm, useFormState } from 'react-final-form'
import { useRedirect, useDataProvider } from 'react-admin'
import { ApolloProvider } from '@apollo/client'
import WarningIcon from '@material-ui/icons/Warning'
import NewClient from '../../../dataProvider/hasura/buildApolloClient'
import ClientQuoteCreate from '../../catalogueProducts/components/ClientQuoteCreate'
import useStyles from '../styles'
import FactoryQuoteCalculator from '../../products/components/FactoryQuoteCalculator'
import AdditionalFilesFieldInput from '../../components/fieldInputs/AdditionalFilesFieldInput'
import MultiplePhotoSelect from '../../components/fieldInputs/MultiplePhotoSelect'
import AutocompleteSpecificationArrayFieldInput from '../../components/fieldInputs/AutocompleteSpecificationArrayFieldInput'
import AutocompleteArrayFieldInput from '../../components/fieldInputs/AutocompleteArrayFieldInput'
import SpecificationArrayFieldInput from '../../components/fieldInputs/SpecificationArrayFieldInput'
import TextFieldInput from '../../components/fieldInputs/TextFieldInput'
import DateFieldInput from '../../components/fieldInputs/DateFieldInput'
import NumberFieldInput from '../../components/fieldInputs/NumberFieldInput'
import BooleanFieldInput from '../../components/fieldInputs/BooleanFieldInput'
import TagSelectWithCreate from '../../components/fieldInputs/TagSelectWithCreate'
import SupplierSelectWithCreate from '../../components/fieldInputs/SupplierSelectWithCreate'
import ProductSizesTable from '../../components/ProductSizesTable'
import ProductOptionsForm from '../../components/ProductOptionsForm'

const FormData = (props) => {
  const {
    packagingTypes = [],
    title,
    imprints = [],
    materials = [],
    index,
    sourcing_request_id,
    milestones = [],
    config = {},
    client_id,
    isManager = false,
    srForm,
  } = props
  const classes = useStyles()
  const form = useForm()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()
  const { values, errors } = useFormState()

  const localToken = localStorage.getItem('token')

  // console.log(localToken)
  const token = typeof localToken === 'string' ? localToken : localToken?.access_token
  const client = NewClient(token)

  const branchProducts =
    values.branch_products?.length > 0
      ? values.branch_products
      : values.root_product?.branch_products

  return (
    <Box className={classes.outerBox}>
      {values.product_quotes?.length > 0 && values.product_quotes[0].scm_quotes?.length > 0 ? (
        <ClientQuoteCreate
          milestones={milestones}
          config={config}
          basePath="/sourcingRequests"
          resource="scmQuote"
          isManager={isManager}
          clientQuoteProductData={values}
          srForm={srForm}
          index={index}
          id={
            values.product_quotes?.length > 0 && values.product_quotes[0].scm_quotes?.length > 0
              ? values.product_quotes[0].scm_quotes[0].id
              : null
          }
          sourcing_request_id={sourcing_request_id}
          product_quote_id={values.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
        />
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button
            disabled={Object.keys(errors).length > 0}
            style={{
              color: 'white',
              backgroundColor: Object.keys(errors).length > 0 ? '#AAAAAA' : '#00B3C3',

              marginTop: '-100px',
              padding: '10px',
            }}
            onClick={async () => {
              const resp = await dataProvider.create('scm_quote', {
                data: {
                  product_quote_id: values.product_quotes[0].id,
                  client_id,
                  sourcing_request_id,
                  container_rate_20ft: Math.round(values.containerRate20ft * 100),
                  container_rate_40ft: Math.round(values.containerRate40ft * 100),
                  container_rate_40ft_hc: Math.round(values.containerRate40ftHC * 100),
                  freight_rate_air: Math.round(values.freightRateAir * 100),
                  freight_rate_sea: Math.round(values.freightRateSea * 100),
                  container_capacity_20ft: values.product_quotes[0].container_capacity_20ft,
                  container_capacity_40ft: values.product_quotes[0].container_capacity_40ft,
                  container_capacity_40ft_hc: values.product_quotes[0].container_capacity_40ft_hc,
                  does_sea_freight_include_duties: values.does_sea_freight_include_duties,
                  duty_rate: Math.round(values.dutyRate * 100),
                  product_components: {
                    data: values.product_quotes[0].components.map((el) => ({
                      name: el.name || '',
                    })),
                  },
                },
              })

              const pq = { ...values.product_quotes[0] }

              pq.scm_quotes.push(resp.data)

              form.change('product_quotes[0]', pq)
            }}
          >
            Add Client Quote
          </Button>
          {Object.keys(errors).length > 0 ? (
            <Tooltip title={Object.values(errors).join(', ')}>
              <WarningIcon style={{ marginTop: '-100px', marginLeft: '20px' }} />
            </Tooltip>
          ) : null}
        </Box>
      )}

      {title && (
        <Box display="flex" justifyContent="space-between">
          <span className={classes.title}>{title}</span>
        </Box>
      )}

      <Box display="flex">
        <Box flex={1}>
          <MultiplePhotoSelect
            id={values.id}
            reference="product_id"
            resource="product_file"
            type="^ADDITIONAL_PHOTO^"
            field="images"
            primaryField="primary_photo"
            onClickPreview={async (file) => {
              await dataProvider.update('product', {
                data: {
                  thumbnail_photo_id: file.file.id,
                  primary_photo_id: file.file.id,
                  id: values.id,
                },
              })
              form.change('primary_photo', file.file)
              form.change('thumbnail_photo', file.file)
            }}
            onSave={async (data) => {
              if (!values.primary_photo?.id) {
                await dataProvider.update('product', {
                  data: {
                    thumbnail_photo_id: data[0].file?.id,
                    primary_photo_id: data[0].file?.id,
                    id: values.id,
                  },
                })
                await form.change('primary_photo', {
                  id: data[0].file?.id,
                  filename: data[0].file?.filename,
                  url: data[0].file.url,
                })
                await form.change('thumbnail_photo', {
                  id: data[0].file?.id,
                  filename: data[0].file?.filename,
                  url: data[0].file.url,
                })
              }
            }}
          />
        </Box>
        <Box flex={2} ml="50px">
          <TextFieldInput
            variant="standard"
            field="name"
            resource="product"
            id={values.id}
            label="Product name:"
            value={values.name}
            onSave={() => srForm.change(`products[${index}].product.name`, values.name)}
          />
          <AutocompleteArrayFieldInput
            variant="standard"
            fullWidth
            reference="categories"
            fieldLabel="Categories"
            elementName="category"
            data={values.categories}
            oldData={values.product_categories}
            resourceName="product_categories"
            fieldName="category_id"
            referenceField="product_id"
            referenceId={values.id}
            classes={{ container: classes.categoryInput }}
          />
          {/* <span className={classes.productSKU}>124-373</span> */}
          <TagSelectWithCreate
            variant="standard"
            fullWidth
            reference="tags"
            fieldLabel="Tags"
            data={values.tags}
            oldData={values.product_tags}
            resourceName="product_tags"
            fieldName="tag_id"
            referenceField="product_id"
            referenceId={values.id}
          />
          <TextFieldInput
            variant="standard"
            field="brand"
            resource="product"
            id={values.id}
            label="Brand:"
            value={values.brand}
          />

          <TextFieldInput
            variant="standard"
            field="short_description"
            resource="product"
            id={values.id}
            label="Short description:"
            value={values.short_description}
          />
          <Box display="flex">
            <Box flex={1}>
              <SupplierSelectWithCreate
                label="Supplier"
                optionText={(value) => {
                  return value?.official_verification_link
                    ? value?.name
                    : `${value?.name} (Unverified)`
                }}
                source={'product_quotes[0].supplier_id'}
              />
            </Box>
            <Box flex={3} />
          </Box>
          <Box mt="30px">
            <span className={classes.labelText}>Specifications</span>
            <ApolloProvider client={client}>
              <SpecificationArrayFieldInput
                variant="standard"
                fullWidth
                fieldLabel="Other specifications"
                resourceName="product_specifications"
                source="otherSpecifications"
                fieldName="product_specifications"
                referenceField="product_id"
                referenceId={values.id}
                oldDataField="oldSpecifications"
              />
            </ApolloProvider>
          </Box>
        </Box>
      </Box>

      <FactoryQuoteCalculator />
      <Box display="flex" mt="30px">
        <DateFieldInput
          variant="standard"
          fullWidth
          field="valid_date"
          formField="product_quotes[0].valid_date"
          resource="product_quote"
          id={values.product_quotes[0].id}
          label="Factory quote valid until:"
          className={classes.fieldInputLeftNarrow}
        />

        <TextFieldInput
          variant="standard"
          fullWidth
          field="hts_code"
          resource="product"
          id={values.id}
          label="HTS code:"
          value={values.hts_code}
          className={classes.fieldInputLeftNarrow}
        />
      </Box>
      <Box display="flex" mt="30px">
        <NumberFieldInput
          className={classes.fieldInputLeftNarrow}
          label="Tooling cost:"
          value={values?.toolingCost}
          resource="product_quote"
          field="tooling_cost"
          defaultValue={0}
          decimalScale={2}
          fixedDecimalScale
          parseMultiplier={100}
          prefix="$ "
          id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
        />
        <NumberFieldInput
          className={classes.fieldInputLeftNarrow}
          label="Tooling lead time:"
          value={
            values?.product_quotes?.length > 0 ? values.product_quotes[0].tooling_lead_time : null
          }
          resource="product_quote"
          field="tooling_lead_time"
          defaultValue={0}
          decimalScale={0}
          fixedDecimalScale
          suffix=" days"
          id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
        />
        <NumberFieldInput
          className={classes.fieldInputLeftNarrow}
          label="Sample cost:"
          value={values?.sampleCost}
          resource="product_quote"
          field="sample_cost"
          defaultValue={0}
          decimalScale={2}
          fixedDecimalScale
          parseMultiplier={100}
          prefix="$ "
          id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
        />
        <NumberFieldInput
          className={classes.fieldInputLeftNarrow}
          label="Custom sample cost:"
          value={values?.customSampleCost}
          resource="product_quote"
          field="custom_sample_cost"
          defaultValue={0}
          decimalScale={2}
          fixedDecimalScale
          parseMultiplier={100}
          prefix="$ "
          id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
        />
        <NumberFieldInput
          className={classes.fieldInputLeftNarrow}
          label="Sample lead time:"
          value={
            values?.product_quotes?.length > 0 ? values.product_quotes[0].sample_lead_time : null
          }
          resource="product_quote"
          field="sample_lead_time"
          defaultValue={0}
          decimalScale={0}
          fixedDecimalScale
          suffix=" days"
          id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
        />
      </Box>

      {packagingTypes?.length > 0 ? <ProductSizesTable packagingTypes={packagingTypes} /> : null}

      <ProductOptionsForm materials={materials} imprints={imprints} />

      {/* <BooleanFieldInput
        field="allow_order_splitting_by_size"
        resource="product_quote"
        id={values.product_quotes[0].id}
        label="Allow order splitting by sizes?"
      /> */}
      {/* <Box mt="30px">
        <span className={classes.sectionHeader}>Standard color options</span>
        <div style={{ width: '100%', height: '20px' }} />
        {values.product_quotes?.length > 0 &&
          values.product_quotes[0].components?.map((comp, index) => (
            <Box
              key={`$component${comp.id}`}
              style={{
                border: 'lightgray solid 1px',
                display: 'flex',
                alignItems: 'center',
                maxWidth: '80vw',
                flexWrap: 'wrap',
              }}
            >
              <span style={{ minWidth: '220px', fontSize: '12px' }}>Component: {comp.name}</span>
              <MultipleColourSelect
                variant="standard"
                fullWidth
                field={`product_quotes[0].components[${index}].colours`}
                resource="component_colours"
                parentField="component_id"
                parentId={comp.id}
            
                label=""
                parentForm={form}
            
                value={
                  values.product_quotes?.length > 0 &&
                  values.product_quotes[0].components[index].colours
                }
              />
            </Box>
          ))}
        <div style={{ width: '100%', height: '20px' }} />
        <NumberFieldInput
          label="Standard Colors MOQ"
          value={
            values?.product_quotes?.length > 0 ? values.product_quotes[0].standard_colour_moq : null
          }
          resource="product_quote"
          field="standard_colour_moq"
          id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
        />
        <div style={{ width: '100%', height: '20px' }} />
        <NumberFieldInput
          className={classes.fieldInputLeftNarrow}
          label="Standard Colors Cost Modifier:"
          value={values?.standardColourCostModifier}
          resource="product_quote"
          field="standard_colour_cost_modifier"
          defaultValue={0}
          decimalScale={2}
          fixedDecimalScale
          parseMultiplier={100}
          prefix="$ "
          id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
        />
        <div style={{ width: '100%', height: '20px' }} /> */}
      <BooleanFieldInput
        field="allow_order_splitting_by_colour"
        resource="product_quote"
        id={values.product_quotes[0].id}
        source="product_quotes[0].allow_order_splitting_by_colour"
        label="Allow order splitting by colors?"
      />
      {/* </Box> */}
      <span className={classes.labelText}>Material(s)</span>

      <AutocompleteSpecificationArrayFieldInput
        variant="standard"
        fullWidth
        filter={{ own: '%material%' }}
        reference="material"
        fieldLabel="Materials"
        elementName="option_value"
        optionName="Material"
        data={values.productMaterials}
        oldData={values.product_specifications}
        resourceName="productMaterials"
        fieldName="product_specifications"
        referenceField="product_id"
        referenceId={values.id}
        onConflict={{
          on_conflict: {
            constraint: '^product_specifications_product_id_specification_id_key^',
            update_columns: ['^product_id^', '^specification_id^'],
          },
        }}
      />
      <Box mt="30px" display="flex" flexDirection="column">
        <TextFieldInput
          variant="standard"
          field="notes"
          className={classes.textInputFull}
          resource="product"
          id={values.id}
          multiline
          style={{
            alignItems: 'start',
          }}
          label="Product notes:"
          value={values.notes}
        />
      </Box>

      <AdditionalFilesFieldInput
        variant="standard"
        fullWidth
        field="additional_files"
        resource="product"
        id={values.id}
        label=""
        value={values.additional_files}
      />

      <Box mt="30px" display="flex" flexDirection="column">
        <span className={classes.labelText}>Product Family</span>
        <Box display="flex" className={classes.relatedProductsContainer}>
          {values.id && values.root_product_id && values.root_product_id !== values.id && (
            <Box key={`root${values.root_product_id}`} className={classes.relatedProductsImageBox}>
              <span className={classes.baseProductLabel}>Base Product</span>
              <img
                src={values.root_product?.thumbnail_photo?.url}
                className={classes.relatedProductsImage}
                alt=""
                onClick={() => redirect(`/products/${values.root_product_id}`)}
              />
              <span>{values.root_product?.name}</span>
            </Box>
          )}
          {branchProducts?.map((item) => (
            <Box key={`branch${item.id}`} className={classes.relatedProductsImageBox}>
              <img
                src={item.thumbnail_photo?.url}
                alt=""
                className={classes.relatedProductsImage}
                onClick={() => redirect(`/products/${item.id}`)}
              />
              <span>{item.name}</span>
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  )
}

export default FormData
