import React, { useState } from 'react'
import { Box, Button, CircularProgress } from '@material-ui/core'
import {
  required,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
  TextInput,
  SelectInput,
  ReferenceInput,
  useNotify,
  useDataProvider,
  // AutocompleteInput,
} from 'react-admin'
import { useForm, useFormState } from 'react-final-form'
import AddIcon from '@material-ui/icons/Add'
import IconButton from '@material-ui/core/IconButton'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'

const RecommendationsFieldInput = (props) => {
  const form = useForm()
  const { values, initialValues } = useFormState()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  const [saving, setSaving] = useState(false)
  const [editing, setEditing] = useState(false)

  const { source, resource, label } = props

  return (
    <Box>
      <Box display="flex" alignItems="center">
        <span style={{ fontSize: '12px' }}>{label}</span>
        {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
        {editing ? (
          <Box display="flex">
            <IconButton
              onClick={async () => {
                try {
                  // console.log(values[source])
                  // console.log(initialValues[source])
                  const newVals = []
                  const updVals = []

                  await Promise.all(
                    values[source]?.map((newItem) => {
                      const oldItem = initialValues[source]?.find((el) => el.id === newItem.id)
                      // console.log(oldItem)
                      // console.log(newItem)
                      if (!oldItem) {
                        newVals.push({
                          sourcing_request_id: values.id,
                          recommendation_id: newItem.recommendation_id,
                          description: newItem.description,
                        })
                      } else if (oldItem.url !== newItem.url) {
                        updVals.push({
                          id: oldItem.id,
                          recommendation_id: newItem.recommendation_id,
                          description: newItem.description,
                        })
                      }
                    }),
                  )
                  const delVals = []
                  await Promise.all(
                    initialValues[source]?.map((oldItem) => {
                      if (!values[source]?.some((el) => el.id === oldItem.id)) {
                        delVals.push(oldItem.id)
                      }
                    }),
                  )

                  // console.log(delVals)
                  // console.log(newVals)
                  // console.log(updVals)
                  if (newVals?.length > 0) {
                    const resp = await dataProvider.createMany(resource, { objects: newVals })
                    // console.log(resp)

                    resp.data.map((el) => {
                      const index = values[source]?.findIndex((obj) => obj.url === el.url)
                      // console.log(index)
                      // console.log(values[source][index])
                      form.change(`${values[source][index]}.id`, el.id)
                    })
                  }

                  if (delVals?.length > 0) {
                    await dataProvider.deleteMany(resource, { ids: delVals })
                  }

                  if (updVals?.length > 0) {
                    await Promise.all(
                      updVals?.map(async (el) => {
                        await dataProvider.update(resource, { data: el })
                      }),
                    )
                  }

                  notify('Update Complete', { type: 'info' })
                  setEditing(false)
                  setSaving(false)
                } catch (error) {
                  console.log(error)
                  notify(error.message.split('.')[0], { type: 'warning' })
                  setEditing(false)
                  setSaving(false)
                }
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
            </IconButton>
            <IconButton
              onClick={() => {
                setEditing(false)
              }}
              style={{ maxWidth: '50px', maxHeight: '50px' }}
            >
              <CancelOutlined fontSize="small" />
            </IconButton>
          </Box>
        ) : (
          <IconButton
            onClick={() => {
              setEditing(true)
            }}
            style={{ maxWidth: '50px', maxHeight: '50px' }}
          >
            <BorderColorIcon fontSize="small" />
          </IconButton>
        )}
      </Box>

      <ArrayInput source={source} label={false}>
        <SimpleFormIterator
          TransitionProps={{ enter: false, exit: false }}
          addButton={
            <Button style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}>
              <AddIcon />
            </Button>
          }
          disableAdd={!editing}
          removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
          disableRemove={!editing}
        >
          <FormDataConsumer>
            {({ getSource, scopedFormData, rest }) => (
              <Box display="flex">
                <ReferenceInput
                  source={getSource('recommendation_id')}
                  {...rest}
                  record={scopedFormData}
                  reference="recommendation"
                  label="Recommendation"
                  alwaysOn
                  disabled={!editing}
                  variant="standard"
                  validate={required()}
                >
                  <SelectInput
                    // optionText={(val) => val.name}
                    fullWidth
                    style={{ width: '200px' }}
                  />
                </ReferenceInput>

                <TextInput
                  source={getSource('description')}
                  {...rest}
                  record={scopedFormData}
                  label="Description"
                  fullWidth
                  disabled={!editing}
                  variant="standard"
                  validate={required()}
                  style={{ marginLeft: '20px', width: '600px' }}
                  // margin="none"
                  // className={`${classes.fieldInputNoPadding}`}
                />
              </Box>
            )}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Box>
  )
}

export default RecommendationsFieldInput
