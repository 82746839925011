import React, { useState } from 'react'
import {
  Box,
  Button,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  CircularProgress,
} from '@material-ui/core'
import {
  //   required,
  DateInput,
  ReferenceInput,
  SelectInput,
  List,
  Datagrid,
  TextField,
  Filter,
  SearchInput,
  FunctionField,
  useRedirect,
  useDataProvider,
} from 'react-admin'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { uniqueId } from 'lodash'
import useStyles from '../styles'
import { getDisplayName } from '../../../utils/utils'
import SupplierDefaultImg from '../../../assets/supplier_default.png'
// import ClientQuoteCreate from './ClientQuoteCreate'
import ProductCreate from './ProductCreate'
import CloseIcon from '@material-ui/icons/RemoveCircleOutline'
import IconButton from '@material-ui/core/IconButton'

const ProductEdit = (props) => {
  const classes = useStyles()
  const redirect = useRedirect()
  const {
    client_id,
    categories,
    packagingTypes,
    materials,
    imprints,
    milestones,
    config,
    setProduct,
    removeProduct,
    products,
    sourcing_request_id,
    isManager,
    srForm,
    srFormVals,
  } = props

  const dataProvider = useDataProvider()
  // console.log('products', products)
  const [loading, setLoading] = useState(false)

  // console.log('SRID', sourcing_request_id)

  return (
    <div>
      {products?.map((product, index) => {
        return (
          <ProductSearch
            key={`prod${product.id || uniqueId()}`}
            index={index}
            client_id={client_id}
            categories={categories}
            packagingTypes={packagingTypes}
            materials={materials}
            imprints={imprints}
            milestones={milestones}
            config={config}
            setProduct={setProduct}
            removeProduct={removeProduct}
            initialProductData={product}
            sourcing_request_id={sourcing_request_id}
            isManager={isManager}
            srForm={srForm}
            srFormVals={srFormVals}
          />
        )
      })}
      <Button
        style={{ marginRight: '10px' }}
        onClick={async () => {
          setLoading(true)
          const resp = await dataProvider.create('sourcing_request_products', {
            data: {
              sourcing_request_id,
              product: {
                data: {
                  name: '',
                  product_dimensions: { data: {} },
                  carton_dimensions: { data: {} },
                  packaging_dimensions: { data: {} },
                  product_quotes: {
                    data: {
                      components: {
                        data: {
                          name: 'Base',
                        },
                      },
                    },
                  },
                },
              },
            },
          })

          const fqResp = await dataProvider.getOne('product', {
            id: resp.data.product_id,
          })
          // console.log(fqResp)

          const productsList = [...srFormVals.products]
          productsList.push({ ...resp.data, product: fqResp.data })

          // console.log(productsList)

          srForm.change('products', productsList)
          setLoading(false)
        }}
      >
        Add a product
      </Button>
      <Button
        style={{ marginLeft: '10px' }}
        onClick={() => redirect(`/productImport/${sourcing_request_id}`)}
      >
        Import products
      </Button>
      {loading && <CircularProgress style={{ width: '20px', height: '20px' }} />}
      {products?.length < 3 && (
        <Typography className={classes.productsWarning}>LESS THAN 3 PRODUCTS</Typography>
      )}
    </div>
  )
}

const ProductSearch = ({
  index,
  client_id,
  materials = [],
  categories = [],
  imprints = [],
  packagingTypes = [],
  suppliers = [],
  config = {},
  milestones = [],
  setSuppliers,
  // setProduct,
  // setDesigns,
  srForm,
  srFormVals,
  // initialProductData,
  removeProduct,
  sourcing_request_id,
  isManager,
}) => {
  const [searchOpen, setSearchOpen] = useState(false)
  const [saving, setSaving] = useState(false)
  // const [productData, setProductData] = useState(initialProductData.product)
  const classes = useStyles()
  const dataProvider = useDataProvider()

  // console.log(productData)

  // srFormVals.products[index].product?.expanded &&
  //   console.log('EXPANDED', index, srFormVals.products[index].product?.expanded)
  // srFormVals.products[index].product?.expanded && console.log(productData)

  const ProductFilter = (props) => (
    <Filter {...props}>
      <SearchInput
        // label="Name/Sku"
        source="request_name"
        alwaysOn
        resettable
        variant="standard"
        fullWidth
        placeholder="Product Name"
        style={{ minWidth: '200px' }}
      />
      <ReferenceInput
        source="supplier"
        reference="suppliers"
        // sort={{ field: 'id', order: 'ASC' }}
        alwaysOn
        style={{ minWidth: '200px' }}
        variant="standard"
      >
        <SelectInput optionText="name" fullWidth resettable />
      </ReferenceInput>
      <ReferenceInput
        source="agent"
        reference="users"
        label="SCA"
        // sort={{ field: 'id', order: 'ASC' }}
        filter={{ roles: { role: { code: { _in: ['sca', 'sca-manager'] } } } }}
        style={{ minWidth: '200px' }}
        alwaysOn
        variant="standard"
      >
        <SelectInput
          optionValue="id"
          optionText={(val) => getDisplayName(val)}
          fullWidth
          resettable
        />
      </ReferenceInput>
      <ReferenceInput
        source="category"
        reference="category"
        // sort={{ field: 'id', order: 'ASC' }}
        style={{ minWidth: '200px' }}
        alwaysOn
        variant="standard"
      >
        <SelectInput optionText="name" fullWidth resettable />
      </ReferenceInput>
      <DateInput
        source="date_gte"
        label="Quote date after"
        alwaysOn
        variant="standard"
        fullWidth
        style={{ minWidth: '120px' }}
      />
      <DateInput
        source="date_lte"
        label="Quote date before"
        alwaysOn
        variant="standard"
        fullWidth
        style={{ minWidth: '120px' }}
      />
      <SelectInput
        choices={[
          { name: 'Yes', value: 'true', id: 0 },
          { name: 'No', value: 'false', id: 1 },
        ]}
        allowEmpty
        emptyText=""
        resettable
        alwaysOn
        fullWidth
        optionValue="value"
        variant="standard"
        label="Quoted"
        source="has_quotes"
        style={{ minWidth: '120px' }}
      />
    </Filter>
  )

  // PRODUCT NO ID = FORM WITH DATA
  // PRODUCT WITH ID = SUMMARY
  // NO PRODUCT = EMPTY FORM

  return (
    <Accordion
      expanded={srFormVals.products[index].expanded || false}
      onChange={() => {
        // console.log('changing')
        srForm.change(`products[${index}].expanded`, !srFormVals.products[index].expanded)
      }}
      className={classes.accordionStyle}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel3a-content"
        id="panel3a-header"
      >
        <Box mr="10px" style={{ width: '100%' }} display="flex" justifyContent="space-between">
          <Typography className={classes.sectionHeader}>
            {`Product ${index + 1} (ID:${srFormVals.products[index].product?.id}) ${
              srFormVals.products[index].product?.product_quotes?.length > 0 &&
              srFormVals.products[index].product?.product_quotes[0].scm_quotes?.length > 0
                ? ' - ' + srFormVals.products[index].product?.product_quotes[0].scm_quotes[0].status
                : ' - PENDING QUOTE'
            } ${
              srFormVals.products[index].product?.name
                ? ' - ' + srFormVals.products[index].product?.name
                : ''
            }`}
          </Typography>
          <Box display="flex" alignItems="center">
            {/* <Button
              className={classes.designButton}
              onClick={(e) => {
                e.stopPropagation()
                setDesigns({ data: { ...productData, id: uniqueId() } })
              }}
            >
              Add a design
            </Button> */}
            <IconButton
              aria-label="delete"
              className={classes.deleteProductBtn}
              onClick={async (e) => {
                e.stopPropagation()

                // console.log(srFormVals.products[index].product?.product.id)
                await dataProvider.delete('product', {
                  id: srFormVals.products[index].product?.id,
                })
                removeProduct(index)
              }}
            >
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box style={{ width: '100%' }}>
          <Button onClick={() => setSearchOpen(true)}>Find a product</Button>

          {srFormVals.products[index].expanded && (
            <Box mt="30px">
              {/* <ApolloProvider client={client}>
                <ClientQuoteCreate
                  milestones={milestones}
                  config={config}
                  basePath="/sourcingRequests"
                  resource="scm_quotes"
                  // factoryPrices={factoryPrices}
                  // clientQuoteProductData={clientQuoteProductData}
                  isManager={isManager}
                  missingFields={getSCMQuoteRequirements(productData)}
                  pieces_per_carton={
                    initialProductData?.product?.product_quotes?.length > 0
                      ? initialProductData?.product.product_quotes[0].pieces_per_carton
                      : 0
                  }
                  dutyRate={initialProductData?.product?.duty_rate / 100 || 0}
                  chargeable_weight_air={
                    initialProductData?.product?.chargeable_weight_air / 100 || 0
                  }
                  chargeable_weight_sea={
                    initialProductData?.product?.chargeable_weight_sea / 100 || 0
                  }
                  product_quote_id={
                    initialProductData?.product?.product_quotes?.length > 0
                      ? initialProductData?.product?.product_quotes[0].id
                      : null
                  }
                  client_id={client_id}
                  sourcing_request_id={sourcing_request_id}
                  isProductValid={isProductValid}
                />
                <div
                  style={{
                    margin: '60px 10px',
                    height: '2px',
                    backgroundColor: 'darkgray',
                  }}
                />
              </ApolloProvider> */}

              <ProductCreate
                index={index}
                // record={productData}
                client_id={client_id}
                basePath="/sourcingRequests"
                resource="products"
                id={srFormVals.products[index].product.id}
                categories={categories}
                suppliers={suppliers}
                packagingTypes={packagingTypes}
                materials={materials}
                imprints={imprints}
                setSuppliers={setSuppliers}
                // setProductData={setProductData}
                // setProduct={setProduct}
                sourcing_request_id={sourcing_request_id}
                milestones={milestones}
                config={config}
                isManager={isManager}
                srForm={srForm}
                // setClientQuoteProductData={setClientQuoteProductData}
              />
            </Box>
          )}

          <Dialog
            onClose={() => setSearchOpen(false)}
            open={searchOpen}
            classes={{ paper: classes.paper }}
            maxWidth="md"
          >
            <DialogTitle className={classes.checkModelHeader}>
              <Box width={800} style={{ maxHeight: 820 }}>
                <Typography variant="h5" gutterBottom>
                  Product Search
                </Typography>
              </Box>
            </DialogTitle>
            <DialogContent>
              <Box>
                <List
                  component="div"
                  actions={null}
                  empty={false}
                  resource="products"
                  basePath="sourcingRequests"
                  bulkActionButtons={false}
                  filters={<ProductFilter />}
                  sort={{ field: 'id', order: 'DESC' }}
                >
                  <Datagrid>
                    <FunctionField
                      label="Image"
                      render={(record) => (
                        <img
                          style={{ maxHeight: '50px' }}
                          src={record.thumbnail_photo?.url || SupplierDefaultImg}
                          // alt=""
                          alt={<img src={SupplierDefaultImg} alt="" />}
                        />
                      )}
                    />
                    {/* <TextField source="id" /> */}
                    <TextField source="name" />
                    <TextField label="Supplier" source="product_quotes[0].supplier.name" />
                    {/* <BooleanField source="commentable" /> */}
                    <FunctionField
                      label="Select"
                      render={(record) => (
                        <Button
                          onClick={async () => {
                            // console.log('product', productData)
                            // console.log('copying', record)
                            setSaving(true)

                            try {
                              // const newProductDimensions = {
                              //   id:
                              //     srFormVals.products[index].product?.product_dimensions?.id ||
                              //     srFormVals.products[index].product?.product_dimensions_id,
                              //   length: record.product_dimensions?.length,
                              //   width: record.product_dimensions?.width,
                              //   height: record.product_dimensions?.height,
                              //   gross_weight: record.product_dimensions?.gross_weight,
                              // }

                              // await dataProvider.update('dimension', { data: newProductDimensions })

                              // const newPackagingDimensions = {
                              //   id:
                              //     srFormVals.products[index].product?.packaging_dimensions?.id ||
                              //     srFormVals.products[index].product?.packaging_dimensions_id,
                              //   length: record.packaging_dimensions?.length,
                              //   width: record.packaging_dimensions?.width,
                              //   height: record.packaging_dimensions?.height,
                              //   gross_weight: record.packaging_dimensions?.gross_weight,
                              // }

                              // await dataProvider.update('dimension', {
                              //   data: newPackagingDimensions,
                              // })

                              // const newCartonDimensions = {
                              //   id:
                              //     srFormVals.products[index].product?.carton_dimensions?.id ||
                              //     srFormVals.products[index].product?.carton_dimensions_id,
                              //   length: record.carton_dimensions?.length,
                              //   width: record.carton_dimensions?.width,
                              //   height: record.carton_dimensions?.height,
                              //   gross_weight: record.carton_dimensions?.gross_weight,
                              // }

                              // await dataProvider.update('dimension', { data: newCartonDimensions })

                              const newProduct = {
                                id: srFormVals.products[index].product?.id,
                                root_product_id: record.id,
                                name: record.name,
                                brand: record.brand,
                                chargeable_weight_air: record.chargeable_weight_air,
                                chargeable_weight_sea: record.chargeable_weight_sea,
                                primary_photo_id: record.primary_photo.id,
                                thumbnail_photo_id: record.thumbnail_photo.id,
                                duty_rate: record.duty_rate,
                                hts_code: record.hts_code,
                                is_catalogue: false,
                                notes: record.notes,
                                sca_user_id: record.sca_user_id,
                                root_sku: record.root_sku,
                                short_description: record.short_description,
                              }

                              await dataProvider.update('product', { data: newProduct })

                              const newProductQuote = {
                                id: srFormVals.products[index].product?.product_quotes[0].id,
                                allow_order_splitting_by_size: record.allow_order_splitting_by_size,
                                allow_order_splitting_by_colour:
                                  record.allow_order_splitting_by_colour,
                                custom_sample_cost: record.product_quotes[0].custom_sample_cost,
                                does_sea_freight_include_duties:
                                  record.product_quotes[0].does_sea_freight_include_duties,
                                freight_rate_air: record.product_quotes[0].freight_rate_air,
                                freight_rate_sea: record.product_quotes[0].freight_rate_sea,
                                sample_cost: record.product_quotes[0].sample_cost,
                                sample_lead_time: record.product_quotes[0].sample_lead_time,
                                supplier_id: record.product_quotes[0].supplier_id,
                                tooling_cost: record.product_quotes[0].tooling_cost,
                                tooling_lead_time: record.product_quotes[0].tooling_lead_time,
                                valid_date: record.product_quotes[0].valid_date,
                              }

                              await dataProvider.update('product_quote', { data: newProductQuote })

                              const newProductQuotePrices = record.product_quotes[0].prices.map(
                                (price) => ({
                                  product_quote_id:
                                    srFormVals.products[index].product?.product_quotes[0].id,
                                  est_landed_cost_air: price.est_landed_cost_air,
                                  est_landed_cost_sea: price.est_landed_cost_sea,
                                  lead_time: price.lead_time,
                                  quantity: price.quantity,
                                  shipment_weight: price.shipment_weight,
                                  total_cartons: price.total_cartons,
                                  total_cbm: price.total_cbm,
                                  total_duties: price.total_duties,
                                  unit_price: price.unit_price,
                                  freight_cost_air: price.freight_cost_air,
                                  freight_cost_sea: price.freight_cost_sea,
                                  containers: price.containers,
                                  freight_cost_air_per_piece: price.freight_cost_air_per_piece,
                                  freight_cost_sea_per_piece: price.freight_cost_sea_per_piece,
                                }),
                              )
                              if (newProductQuotePrices?.length > 0) {
                                await dataProvider.create('product_quote_price', {
                                  data: newProductQuotePrices,
                                })
                              }

                              const newImages = record.images?.map((image) => ({
                                product_id: srFormVals.products[index].product?.id,
                                file_id: image.file.id,
                                type: image.type,
                              }))

                              if (newImages?.length > 0) {
                                await dataProvider.create('product_file', {
                                  data: newImages,
                                })
                              }

                              const newCategories = record.product_categories?.map((cat) => ({
                                product_id: srFormVals.products[index].product?.id,
                                category_id: cat.category.id,
                              }))

                              if (newCategories?.length > 0) {
                                await dataProvider.create('product_categories', {
                                  data: newCategories,
                                })
                              }

                              const newOptions = record.product_options?.map((opt) => ({
                                product_id: srFormVals.products[index].product?.id,
                                specification_id: opt.option_value.id,
                              }))

                              if (newOptions?.length > 0) {
                                await dataProvider.create('product_options', {
                                  data: newOptions,
                                })
                              }

                              const newSpecifications = record.product_specifications?.map(
                                (spec) => ({
                                  product_id: srFormVals.products[index].product?.id,
                                  specification_id: spec.option_value.id,
                                }),
                              )

                              if (newSpecifications?.length > 0) {
                                await dataProvider.create('product_specifications', {
                                  data: newSpecifications,
                                })
                              }

                              const newTags = record.product_tags?.map((tag) => ({
                                product_id: srFormVals.products[index].product?.id,
                                tag_id: tag.tag.id,
                              }))

                              if (newTags?.length > 0) {
                                await dataProvider.create('product_tags', {
                                  data: newTags,
                                })
                              }

                              const included_packaging_option =
                                record.product_quotes[0].packaging_options?.find(
                                  (opt) =>
                                    opt.id ===
                                    record.product_quotes[0].included_packaging_option_id,
                                )

                              // console.log(included_packaging_option)

                              if (included_packaging_option) {
                                await Promise.all(
                                  included_packaging_option.product_quote_product_size_packagings?.map(
                                    async (pkgSize) => {
                                      const size = record.product_quotes[0].product_sizes.find(
                                        (sizeOpt) =>
                                          sizeOpt.id === pkgSize.product_quote_product_size_id,
                                      )
                                      const pkgOptParams = {
                                        packaging_option: {
                                          data: {
                                            product_quote_id:
                                              srFormVals.products[index].product?.product_quotes[0]
                                                .id,
                                            type: included_packaging_option.type,
                                            label: included_packaging_option.label,
                                            moq: included_packaging_option.moq,
                                            cost_modifier: included_packaging_option.cost_modifier,
                                            pieces_per_carton:
                                              included_packaging_option.pieces_per_carton,
                                            packaging_dimensions: {
                                              data: {
                                                ...(included_packaging_option.packaging_dimensions
                                                  ?.length && {
                                                  length:
                                                    included_packaging_option.packaging_dimensions
                                                      .length,
                                                }),
                                                ...(included_packaging_option.packaging_dimensions
                                                  ?.height && {
                                                  height:
                                                    included_packaging_option.packaging_dimensions
                                                      .height,
                                                }),
                                                ...(included_packaging_option.packaging_dimensions
                                                  ?.width && {
                                                  width:
                                                    included_packaging_option.packaging_dimensions
                                                      .width,
                                                }),
                                                ...(included_packaging_option.packaging_dimensions
                                                  ?.gross_weight && {
                                                  gross_weight:
                                                    included_packaging_option.packaging_dimensions
                                                      .gross_weight,
                                                }),
                                              },
                                            },
                                            carton_dimensions: {
                                              data: {
                                                ...(included_packaging_option.carton_dimensions
                                                  ?.length && {
                                                  length:
                                                    included_packaging_option.carton_dimensions
                                                      .length,
                                                }),
                                                ...(included_packaging_option.carton_dimensions
                                                  ?.height && {
                                                  height:
                                                    included_packaging_option.carton_dimensions
                                                      .height,
                                                }),
                                                ...(included_packaging_option.carton_dimensions
                                                  ?.width && {
                                                  width:
                                                    included_packaging_option.carton_dimensions
                                                      .width,
                                                }),
                                                ...(included_packaging_option.carton_dimensions
                                                  ?.gross_weight && {
                                                  gross_weight:
                                                    included_packaging_option.carton_dimensions
                                                      .gross_weight,
                                                }),
                                              },
                                            },
                                          },
                                        },
                                        product_size: {
                                          data: {
                                            product_quote_id:
                                              srFormVals.products[index].product?.product_quotes[0]
                                                .id,
                                            name: size.name,
                                            short_name: size.short_name,
                                            moq: size.moq,
                                            cost_modifier: size.cost_modifier,
                                            dimensions: {
                                              data: {
                                                ...(size.dimensions?.length && {
                                                  length: size.dimensions.length,
                                                }),
                                                ...(size.dimensions?.height && {
                                                  height: size.dimensions.height,
                                                }),
                                                ...(size.dimensions?.width && {
                                                  width: size.dimensions.width,
                                                }),
                                                ...(size.dimensions?.gross_weight && {
                                                  gross_weight: size.dimensions.gross_weight,
                                                }),
                                              },
                                            },
                                          },
                                        },
                                      }

                                      // console.log(pkgOptParams)
                                      const resp = await dataProvider.create(
                                        'product_quote_product_size_packagings',
                                        {
                                          data: pkgOptParams,
                                        },
                                      )

                                      await dataProvider.update('product_quote', {
                                        data: {
                                          id: srFormVals.products[index].product?.product_quotes[0]
                                            .id,
                                          included_packaging_option_id: resp.data.id,
                                        },
                                      })
                                    },
                                  ),
                                )
                              }

                              const newPackagingSizeOptions = []
                              record.product_quotes[0].packaging_options
                                ?.filter(
                                  (opt) =>
                                    opt.id !==
                                    record.product_quotes[0].included_packaging_option_id,
                                )
                                ?.map((pkgOpt) => {
                                  pkgOpt.product_quote_product_size_packagings?.map((pkgSize) => {
                                    const size = record.product_quotes[0].product_sizes.find(
                                      (sizeOpt) =>
                                        sizeOpt.id === pkgSize.product_quote_product_size_id,
                                    )
                                    newPackagingSizeOptions.push({
                                      packaging_option: {
                                        data: {
                                          product_quote_id:
                                            srFormVals.products[index].product?.product_quotes[0]
                                              .id,
                                          type: pkgOpt.type,
                                          label: pkgOpt.label,
                                          moq: pkgOpt.moq,
                                          cost_modifier: pkgOpt.cost_modifier,
                                          pieces_per_carton: pkgOpt.pieces_per_carton,
                                          packaging_dimensions: {
                                            data: {
                                              ...(pkgOpt.packaging_dimensions?.length && {
                                                length: pkgOpt.packaging_dimensions.length,
                                              }),
                                              ...(pkgOpt.packaging_dimensions?.height && {
                                                height: pkgOpt.packaging_dimensions.height,
                                              }),
                                              ...(pkgOpt.packaging_dimensions?.width && {
                                                width: pkgOpt.packaging_dimensions.width,
                                              }),
                                              ...(pkgOpt.packaging_dimensions?.gross_weight && {
                                                gross_weight:
                                                  pkgOpt.packaging_dimensions.gross_weight,
                                              }),
                                            },
                                          },
                                          carton_dimensions: {
                                            data: {
                                              ...(pkgOpt.carton_dimensions?.length && {
                                                length: pkgOpt.carton_dimensions.length,
                                              }),
                                              ...(pkgOpt.carton_dimensions?.height && {
                                                height: pkgOpt.carton_dimensions.height,
                                              }),
                                              ...(pkgOpt.carton_dimensions?.width && {
                                                width: pkgOpt.carton_dimensions.width,
                                              }),
                                              ...(pkgOpt.carton_dimensions?.gross_weight && {
                                                gross_weight: pkgOpt.carton_dimensions.gross_weight,
                                              }),
                                            },
                                          },
                                        },
                                      },
                                      product_size: {
                                        data: {
                                          product_quote_id:
                                            srFormVals.products[index].product?.product_quotes[0]
                                              .id,
                                          name: size.name,
                                          short_name: size.short_name,
                                          moq: size.moq,
                                          cost_modifier: size.cost_modifier,
                                          dimensions: {
                                            data: {
                                              ...(size.dimensions?.length && {
                                                length: size.dimensions.length,
                                              }),
                                              ...(size.dimensions?.height && {
                                                height: size.dimensions.height,
                                              }),
                                              ...(size.dimensions?.width && {
                                                width: size.dimensions.width,
                                              }),
                                              ...(size.dimensions?.gross_weight && {
                                                gross_weight: size.dimensions.gross_weight,
                                              }),
                                            },
                                          },
                                        },
                                      },
                                    })
                                  })
                                })

                              if (newPackagingSizeOptions?.length > 0) {
                                await dataProvider.create('product_quote_product_size_packagings', {
                                  data: newPackagingSizeOptions,
                                })
                              }

                              const newComponents = record.product_quotes[0].components?.map(
                                (component) => ({
                                  product_quote_id:
                                    srFormVals.products[index].product?.product_quotes[0].id,
                                  name: component.name,
                                  svg_shape_index: component.svg_shape_index,
                                  default_colour: component.default_colour,
                                  svg_shape_id: component.svg_shape_id,
                                  allow_custom_colour: component.allow_custom_colour,
                                  colours: {
                                    data: component.colours?.map((col) => ({
                                      value: col.value,
                                    })),
                                  },
                                }),
                              )

                              if (newComponents?.length > 0) {
                                await dataProvider.create('component', { data: newComponents })
                              }

                              // // copy packagings
                              // const newPackagings = record.packaging_options?.map(pkgOpt => ({
                              //   product_quote_id:
                              //   srFormVals.products[index].product?.product_quotes[0].id,
                              //   type: pkgOpt.type,
                              //   label: pkgOpt.label,
                              //   moq: pkgOpt.moq,
                              //   cost_modifier: pkgOpt.cost_modifier,
                              //   pieces_per_carton: pkgOpt.pieces_per_carton,
                              //   packaging_dimensions: {
                              //     data: {
                              //       ...(pkgOpt.packaging_dimensions?.id && { id: pkgOpt.packaging_dimensions.id}),
                              //       ...(pkgOpt.packaging_dimensions?.length && { length: pkgOpt.packaging_dimensions.length}),
                              //       ...(pkgOpt.packaging_dimensions?.height && { height: pkgOpt.packaging_dimensions.height}),
                              //       ...(pkgOpt.packaging_dimensions?.width && { width: pkgOpt.packaging_dimensions.width}),
                              //       ...(pkgOpt.packaging_dimensions?.gross_weight && { gross_weight: pkgOpt.packaging_dimensions.gross_weight}),
                              //     }
                              //   },
                              //   carton_dimensions: {
                              //     data: {
                              //       ...(pkgOpt.carton_dimensions?.id && { id: pkgOpt.carton_dimensions.id}),
                              //       ...(pkgOpt.carton_dimensions?.length && { length: pkgOpt.carton_dimensions.length}),
                              //       ...(pkgOpt.carton_dimensions?.height && { height: pkgOpt.carton_dimensions.height}),
                              //       ...(pkgOpt.carton_dimensions?.width && { width: pkgOpt.carton_dimensions.width}),
                              //       ...(pkgOpt.carton_dimensions?.gross_weight && { gross_weight: pkgOpt.carton_dimensions.gross_weight}),
                              //     }
                              //   }

                              // }))

                              // if (newPackagings?.length > 0) {
                              //   await dataProvider.create('product_quote_packaging_options', {
                              //     data: newPackagings
                              //   })
                              // }

                              // // copy sizes
                              // const newSizes = record.product_sizes?.map(size => ({
                              //   product_quote_id:
                              //   srFormVals.products[index].product?.product_quotes[0].id,
                              //   name: size.name,
                              //   short_name: size.short_name,
                              //   moq: size.moq,
                              //   cost_modifier: size.cost_modifier,
                              //   dimensions: {
                              //     data: {
                              //       ...(size.dimensions?.id && { id: size.dimensions.id}),
                              //       ...(size.dimensions?.length && { length: size.dimensions.length}),
                              //       ...(size.dimensions?.height && { height: size.dimensions.height}),
                              //       ...(size.dimensions?.width && { width: size.dimensions.width}),
                              //       ...(size.dimensions?.gross_weight && { gross_weight: size.dimensions.gross_weight}),
                              //     }
                              //   }
                              // }))

                              // if (newPackagings?.length > 0) {
                              //   await dataProvider.create('product_quote_product_sizes', {
                              //     data: newSizes
                              //   })
                              // }

                              // copy components

                              // copy colours

                              // console.log('NEWPROD', newProduct)
                              // console.log('NEWPRODQUOTE', newProductQuote)
                              // console.log('NEWPRODQTPRICES', newProductQuotePrices)
                              // console.log('NEWPRODIMAGES', newImages)
                              // console.log('NEWPRODCATS', newCategories)
                              // console.log('NEWPRODOPTS', newOptions)
                              // console.log('NEWPRODSPECS', newSpecifications)
                              // console.log('NEWPRODTAGS', newTags)
                              // console.log('NEWPRODSIZES', newPackagingSizeOptions)
                              // console.log('NEWCOMPONENTS', newComponents)
                            } catch (error) {
                              console.log('ERR', error)
                            }

                            // const newRecord = cloneDeep(record)
                            // delete newRecord.id
                            // newRecord.root_product_id = record.id
                            // newRecord.is_catalogue = false
                            // delete newRecord.status

                            // delete newRecord.additional_files
                            // newRecord.additional_files = record.additional_files
                            //   ?.filter((item) => item.deleted_at)
                            //   .map((item) => {
                            //     return {
                            //       file: {
                            //         id: item.file?.id,
                            //         url: item.file?.url,
                            //         filename: item.file?.filename,
                            //       },
                            //       type: item.type,
                            //     }
                            //   })

                            // delete newRecord.images
                            // newRecord.images = record.images
                            //   ?.filter((item) => !item.deleted_at)
                            //   .map((item) => {
                            //     // console.log('IMAGE', item)
                            //     return {
                            //       file: {
                            //         filename: item.file.filename,
                            //         url: item.file.url,
                            //         id: item.file.id,
                            //       },
                            //       type: item.type,
                            //     }
                            //   })

                            // for (let i = 0; i < newRecord.product_specifications?.length; i++) {
                            //   delete newRecord.product_specifications[i].id
                            // }
                            // for (let i = 0; i < newRecord.customisations?.length; i++) {
                            //   delete newRecord.customisations[i].id
                            // }

                            // newRecord.prices =
                            //   newRecord?.product_quotes?.length > 0 &&
                            //   newRecord?.product_quotes[0].prices?.length > 0
                            //     ? newRecord?.product_quotes[0]?.prices?.map((item) => ({
                            //         unit_price: roundTo(item.unit_price / 100, 2),
                            //         production_lead_time: item.lead_time,
                            //         quantity: item.quantity,
                            //       }))
                            //     : []

                            // delete newRecord.packaging_dimensions?.id
                            // delete newRecord.product_dimensions?.id
                            // delete newRecord.carton_dimensions?.id

                            // // newRecord.primary_photo_id = record.primary_photo.id
                            // // newRecord.thumbnail_photo_id = record.thumbnail_photo.id
                            // newRecord.primary_photo = {
                            //   id: record.primary_photo.id,
                            //   url: record.primary_photo.url,
                            //   filename: record.primary_photo.filename,
                            // }
                            // newRecord.thumbnail_photo = {
                            //   id: record.thumbnail_photo.id,
                            //   url: record.thumbnail_photo.url,
                            //   filename: record.thumbnail_photo.filename,
                            // }

                            // if (newRecord.product_quotes?.length > 0) {
                            //   delete newRecord.product_quotes[0].id
                            //   for (let i = 0; i < newRecord.product_quotes[0].prices.length; i++) {
                            //     delete newRecord.product_quotes[0].prices[i].id
                            //   }
                            //   for (
                            //     let i = 0;
                            //     i < newRecord.product_quotes[0].specifications.length;
                            //     i++
                            //   ) {
                            //     delete newRecord.product_quotes[0].specifications[i].id
                            //   }

                            //   if (newRecord.product_quotes[0]?.scm_quotes?.length > 0) {
                            //     delete newRecord.product_quotes[0]?.scm_quotes[0].id
                            //     for (
                            //       let i = 0;
                            //       i < newRecord.product_quotes[0].scm_quotes[0].prices?.length;
                            //       i++
                            //     ) {
                            //       delete newRecord.product_quotes[0].scm_quotes[0].prices[i].id
                            //     }
                            //   }
                            // }
                            // console.log('NEWRECORD', newRecord)

                            const { data: newRecord } = await dataProvider.getOne('product', {
                              id: srFormVals.products[index].product?.id,
                            })
                            // console.log('NEWRECORD', newRecord)
                            // setProductData(newRecord)
                            const productsList = [...srFormVals.products]
                            productsList[index] = { id: uniqueId(), product: newRecord }

                            // console.log(productsList)

                            srForm.change('products', productsList)
                            // await setProduct({ index, data: newRecord })
                            await setSaving(false)
                            setSearchOpen(false)
                          }}
                        >
                          Select
                        </Button>
                      )}
                    />
                  </Datagrid>
                </List>
              </Box>
            </DialogContent>

            <DialogActions>
              <Box display="flex" width={1} justifyContent="center" style={{ paddingBottom: 50 }}>
                {saving && <CircularProgress style={{ width: '20px', height: '20px' }} />}
                <Button
                  onClick={() => setSearchOpen(false)}
                  size="large"
                  style={{ background: '#21C6CF', color: '#fff' }}
                  disabled={saving}
                >
                  CLOSE
                </Button>
              </Box>
            </DialogActions>
          </Dialog>
        </Box>
      </AccordionDetails>
    </Accordion>
  )
}

export default ProductEdit
