import React, { useState, useEffect } from 'react'
import { Box, CircularProgress } from '@material-ui/core'
import { useNotify, useDataProvider } from 'react-admin'
import { useFormState, useForm } from 'react-final-form'
import { useDropzone } from 'react-dropzone'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import CancelIcon from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'
import uploadFile from '../../../dataProvider/aws_upload'
import useStyles from '../styles'

const ImprintFilesFieldInput = (props) => {
  const form = useForm()
  const { values } = useFormState()
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const [uploading, setUploading] = useState(false)

  const [editing, setEditing] = useState(false)
  const [previews, setPreviews] = useState([])

  // console.log(previews)

  const { field, label, resource, type } = props

  useEffect(() => {
    // console.log(previews)
    const formPreviews = values[field]?.map((file, ind) => {
      return (
        <Box
          key={`imprintPreview${file.file?.name || file.id}`}
          className={classes.previewImageBox}
        >
          <img
            src={file.file?.url}
            alt={
              <a href={file.file?.url || file.file?.path}>
                {`${file.file?.name || file.file?.filename}`}
              </a>
            }
            className={classes.previewImprintImage}
          />
          <IconButton
            aria-label="delete"
            className={classes.deleteBtn}
            // disabled={!editing}
            onClick={async () => {
              // console.log(values[field])
              const files = values[field]
              files.splice(ind, 1)
              if (typeof file.id === 'number') {
                await dataProvider.delete(resource, {
                  data: { id: file.id },
                })
              }
              // console.log('NEWARRAY', files)
              setPreviews(files)
              // console.log(values[field])
            }}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      )
    })
    setPreviews(formPreviews)
  }, [values[field]])

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => values[field]?.forEach((file) => URL.revokeObjectURL(file.url))
  }, [])

  const { getRootProps, getInputProps } = useDropzone({
    // accept: ['image/*'],
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        // console.log(acceptedFiles)
        setUploading(true)
        const files = [...values.additional_files]

        try {
          await Promise.all(
            acceptedFiles.map(async (document) => {
              const resp = await uploadFile(document)
              // console.log('UPRESP', resp)

              // const fileResp = await dataProvider.create('file', {
              //   data: { filename: document.name, url: resp },
              // })
              const fileResp = await dataProvider.create(resource, {
                data: {
                  sourcing_request_id: values.id,
                  type,
                  file: { data: { filename: document.name, url: resp } },
                },
              })

              // console.log('FRESP', fileResp)

              files.push({
                id: fileResp.data.id,
                type,
                file: { id: fileResp.data.id, url: resp, filename: document.name },
              })
            }),
          )
          // console.log(files)

          // console.log('changing')
          form.change(field, files)

          setUploading(false)
          setEditing(false)

          notify('Upload complete', { type: 'info' })
        } catch (error) {
          notify(error.message, { type: 'warning' })
          setUploading(false)
          setEditing(false)
        }
      }
    },
  })

  return (
    <div>
      <Box display="flex" alignItems="center">
        <span style={{ fontSize: '12px' }}>{label}</span>
        <Box display="flex">
          {editing ? (
            <Box display="flex">
              <IconButton
                onClick={async () => {
                  setEditing(false)
                }}
                style={{ marginLeft: '30px', maxWidth: '50px', maxHeight: '50px' }}
              >
                <CheckCircleOutlineOutlined fontSize="small" />
              </IconButton>
              <IconButton
                onClick={() => {
                  setEditing(false)
                }}
                style={{ maxWidth: '50px', maxHeight: '50px' }}
              >
                <CancelOutlined fontSize="small" />
              </IconButton>
            </Box>
          ) : (
            <IconButton
              onClick={() => {
                setEditing(true)
              }}
              style={{ marginLeft: '30px', maxWidth: '50px', maxHeight: '50px' }}
            >
              <BorderColorIcon fontSize="small" />
            </IconButton>
          )}
        </Box>
      </Box>
      <Box>
        {editing && (
          <div
            {...getRootProps({ className: classes.reportDropZone })}
            style={{ cursor: 'pointer' }}
          >
            <input disabled={!editing} {...getInputProps()} />
            <span>{`Drag & drop some files here, or click to select files`}</span>
          </div>
        )}
        <Box className={classes.previewsContainerImprints}>{previews}</Box>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        style={{
          // overflowY: 'auto',
          // width: '200px',
          margin: '5px 0px 5px',
          // maxHeight: '50px',
        }}
        justifyContent="center"
        alignItems="baseline"
      >
        {uploading && <CircularProgress style={{ width: '20px', height: '20px' }} />}
      </Box>
    </div>
  )
}

export default ImprintFilesFieldInput
