import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core'
import {
  useNotify,
  useDataProvider,
  TextInput,
  NumberInput,
  SelectInput,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
} from 'react-admin'
import { useFormState, useForm } from 'react-final-form'
import BorderColorIcon from '@material-ui/icons/BorderColorOutlined'
import CancelOutlined from '@material-ui/icons/CancelOutlined'
import CheckCircleOutlineOutlined from '@material-ui/icons/CheckCircleOutlineOutlined'
import IconButton from '@material-ui/core/IconButton'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import ChipInput from '../../../components/input/ChipInput'
import { ColorInput } from '../../components/ColourPicker'
import useStyles from '../styles'

const CriteriaFieldInput = (props) => {
  const form = useForm()
  const { values } = useFormState()
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()

  // console.log(values)

  // console.log('CHIPEDITPROPS', props)

  const [editing, setEditing] = useState(false)

  const { fieldName, oldData, referenceType, referenceId, source } = props

  return (
    <Box display="flex">
      <Box display="flex" alignItems="center" style={{ width: '100%' }}>
        <ArrayInput source={source} label="">
          <SimpleFormIterator
            TransitionProps={{ enter: false, exit: false }}
            addButton={
              <Button style={{ width: '550px', backgroundColor: '#F8F8FA', color: 'black' }}>
                <AddIcon />
              </Button>
            }
            disableAdd={!editing}
            removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
            disableRemove={!editing}
          >
            <FormDataConsumer>
              {({ getSource, scopedFormData, rest }) => {
                if (
                  scopedFormData?.criteria == 'color' ||
                  scopedFormData?.criteria == 'colour' ||
                  scopedFormData?.criteria == 'Color' ||
                  scopedFormData?.criteria == 'Colour'
                ) {
                  // console.log('COLOURSCOPED', scopedFormData)
                  switch (scopedFormData?.format) {
                    case 'HEX':
                      return (
                        <Box display="flex">
                          <TextInput
                            source={getSource('criteria')}
                            {...rest}
                            record={scopedFormData}
                            label="Criteria"
                            // fullWidth
                            variant="standard"
                            margin="none"
                            disabled={!editing}
                            className={`${classes.fieldInputNoMP}`}
                          />
                          <SelectInput
                            label="Format"
                            className={classes.textField}
                            source={getSource('format')}
                            {...rest}
                            record={scopedFormData}
                            optionValue="value"
                            optionText="value"
                            choices={[
                              { value: 'HEX' },
                              { value: 'RGB' },
                              { value: 'CMYK' },
                              { value: 'PANTONE' },
                            ]}
                          />
                          <ColorInput
                            source={getSource('value')}
                            {...rest}
                            record={scopedFormData}
                            className={`${classes.hideLabel} ${classes.colourPickerItem}`}
                            picker="Sketch"
                            label="HEX"
                            // validate={required()}
                          />
                        </Box>
                      )
                    case 'RGB':
                      return (
                        <Box display="flex">
                          <TextInput
                            source={getSource('criteria')}
                            {...rest}
                            record={scopedFormData}
                            label="Criteria"
                            // fullWidth
                            variant="standard"
                            margin="none"
                            disabled={!editing}
                            className={`${classes.fieldInputNoMP}`}
                          />
                          <SelectInput
                            label="Format"
                            className={classes.textField}
                            source={getSource('format')}
                            {...rest}
                            record={scopedFormData}
                            optionValue="value"
                            optionText="value"
                            choices={[
                              { value: 'HEX' },
                              { value: 'RGB' },
                              { value: 'CMYK' },
                              { value: 'PANTONE' },
                            ]}
                          />

                          <ColorInput
                            source={getSource('value')}
                            {...rest}
                            record={scopedFormData}
                            className={`${classes.hideLabel} ${classes.colourPickerItem}`}
                            picker="Sketch"
                            label="RGB (converted to Hex)"
                            // validate={required()}
                          />
                        </Box>
                      )
                    case 'CMYK':
                      return (
                        <Box display="flex">
                          <TextInput
                            source={getSource('criteria')}
                            {...rest}
                            record={scopedFormData}
                            label="Criteria"
                            // fullWidth
                            variant="standard"
                            margin="none"
                            disabled={!editing}
                            className={`${classes.fieldInputNoMP}`}
                          />
                          <SelectInput
                            label="Format"
                            className={classes.textField}
                            source={getSource('format')}
                            {...rest}
                            record={scopedFormData}
                            optionValue="value"
                            optionText="value"
                            choices={[
                              { value: 'HEX' },
                              { value: 'RGB' },
                              { value: 'CMYK' },
                              { value: 'PANTONE' },
                            ]}
                          />

                          <NumberInput
                            source={getSource('value[0]')}
                            {...rest}
                            record={scopedFormData}
                            label="C"
                            // fullWidth
                            variant="standard"
                            margin="none"
                            disabled={!editing}
                            className={`${classes.fieldInputNoMP}`}
                          />
                          <NumberInput
                            source={getSource('value[1]')}
                            {...rest}
                            record={scopedFormData}
                            label="M"
                            // fullWidth
                            variant="standard"
                            margin="none"
                            disabled={!editing}
                            className={`${classes.fieldInputNoMP}`}
                          />
                          <NumberInput
                            source={getSource('value[2]')}
                            {...rest}
                            record={scopedFormData}
                            label="Y"
                            // fullWidth
                            variant="standard"
                            margin="none"
                            disabled={!editing}
                            className={`${classes.fieldInputNoMP}`}
                          />
                          <NumberInput
                            source={getSource('value[3]')}
                            {...rest}
                            record={scopedFormData}
                            label="K"
                            // fullWidth
                            variant="standard"
                            margin="none"
                            disabled={!editing}
                            className={`${classes.fieldInputNoMP}`}
                          />
                        </Box>
                      )
                    case 'PANTONE':
                      return (
                        <Box display="flex">
                          <TextInput
                            source={getSource('criteria')}
                            {...rest}
                            record={scopedFormData}
                            label="Criteria"
                            // fullWidth
                            variant="standard"
                            margin="none"
                            disabled={!editing}
                            className={`${classes.fieldInputNoMP}`}
                          />
                          <SelectInput
                            label="Format"
                            className={classes.textField}
                            source={getSource('format')}
                            {...rest}
                            record={scopedFormData}
                            optionValue="value"
                            optionText="value"
                            choices={[
                              { value: 'HEX' },
                              { value: 'RGB' },
                              { value: 'CMYK' },
                              { value: 'PANTONE' },
                            ]}
                          />

                          <TextInput
                            source={getSource('value')}
                            {...rest}
                            record={scopedFormData}
                            label="Code"
                            // fullWidth
                            variant="standard"
                            margin="none"
                            disabled={!editing}
                            className={`${classes.fieldInputNoMP}`}
                          />
                        </Box>
                      )
                    default:
                      return (
                        <Box display="flex">
                          <TextInput
                            source={getSource('criteria')}
                            {...rest}
                            record={scopedFormData}
                            label="Criteria"
                            // fullWidth
                            variant="standard"
                            margin="none"
                            disabled={!editing}
                            className={`${classes.fieldInputNoMP}`}
                          />
                          <SelectInput
                            label="Format"
                            className={classes.textField}
                            source={getSource('format')}
                            {...rest}
                            record={scopedFormData}
                            optionValue="value"
                            optionText="value"
                            choices={[
                              { value: 'HEX' },
                              { value: 'RGB' },
                              { value: 'CMYK' },
                              { value: 'PANTONE' },
                            ]}
                          />
                        </Box>
                      )
                  }
                } else {
                  // console.log('OTHERSCOPED', scopedFormData)
                  // console.log(getSource('value'))
                  // console.log(Array.isArray(scopedFormData?.value))
                  if (!Array.isArray(scopedFormData?.value)) {
                    form.change(getSource('value'), [])
                    return null
                  }
                  return (
                    <Box display="flex">
                      <TextInput
                        source={getSource('criteria')}
                        {...rest}
                        record={scopedFormData}
                        label={false}
                        // fullWidth
                        variant="standard"
                        margin="none"
                        disabled={!editing}
                        className={`${classes.fieldInputNoMP}`}
                      />
                      <ChipInput
                        source={getSource('value')}
                        {...rest}
                        record={scopedFormData}
                        label={false}
                        className={`${classes.arrayFieldInput}`}
                        style={{ marginLeft: '20px' }}
                        variant="standard"
                        margin="none"
                        disabled={!editing}
                        // {...rest}
                      />
                    </Box>
                  )
                }
              }}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
      {editing ? (
        <Box display="flex">
          <IconButton
            onClick={async () => {
              const newVals = []
              // const retainVals = []
              const delVals = []
              const updVals = []

              // console.log('OLDDATA', oldData)
              values[source].map((item) => {
                // console.log('ITEM', item)

                const val =
                  item.format === 'HEX' || item.format === 'PANTONE' || item.format === 'RGB'
                    ? item.value
                    : { C: item.value[0], M: item.value[1], Y: item.value[2], K: item.value[3] }
                const stringValue = beniColorToString({ type: item.format, val })

                if (
                  item.criteria === 'colour' ||
                  item.criteria === 'color' ||
                  item.criteria === 'Colour' ||
                  item.criteria === 'Color'
                ) {
                  if (!item.id) {
                    newVals.push({
                      criteria: item.criteria,
                      value: stringValue,
                      sourcing_request_id: referenceId,
                      type: referenceType,
                      description: item.format,
                    })
                  } else {
                    // const oldItem = oldData.find((el) => el.id === item.id)
                    const stringValue = beniColorToString({ type: item.format, val })

                    // console.log('OLDVAL', item.value, 'NEWVAL', val)

                    const diffVal =
                      item.format === 'CMYK'
                        ? val.C === item.value[0] &&
                          val.M === item.value[1] &&
                          val.Y === item.value[2] &&
                          val.K === item.value[3]
                        : item.value == val

                    // console.log(diffVal)
                    if (!diffVal) {
                      updVals.push({
                        id: item.id,
                        value: stringValue,
                        description: item.format,
                      })
                    }
                  }
                } else {
                  // console.log(updVals)

                  // eslint-disable-next-line no-unreachable
                  const opt = oldData.find((old) => old.criteria === item.criteria)

                  // console.log('OPT', opt)
                  // check if opt exists, if not, push all values for this criteria to new
                  if (!opt) {
                    // console.log('ADDING', item)
                    item.value.map((val) => {
                      // console.log('add new', val)
                      newVals.push({
                        criteria: item.criteria,
                        value: val,
                        sourcing_request_id: referenceId,
                        type: referenceType,
                      })
                    })
                    // eslint-disable-next-line no-unreachable
                  } else {
                    // loop criteria vals
                    item.value.map((val) => {
                      // console.log('VAL', val)
                      const oldValue = oldData.find((old) => old.value === val)
                      if (!oldValue) {
                        // if old data doesn't exist, add to newVals
                        //   console.log('add new', val)
                        newVals.push({
                          criteria: item.criteria,
                          value: val,
                          sourcing_request_id: referenceId,
                          type: referenceType,
                        })
                        // } else {
                        // else add to retainVails
                        //   console.log('retain', val)
                        // retainVals.push({
                        //   id: oldValue.id,
                        //   criteria: item.criteria,
                        //   value: val,
                        //   sourcing_request_id: referenceId,
                        //   type: referenceType,
                        // })
                      }
                    })
                  }
                }
              })

              // map old data
              oldData.map((item) => {
                // console.log('oldItem', item)

                const delOpt = values[source].find(
                  (val) => val.criteria === item.criteria || val.id === item.id,
                )
                // console.log('delOpt', delOpt)
                if (!delOpt) {
                  // if old option does not exist in new, delete it
                  // console.log('deleting old option')
                  delVals.push(item.id)
                }
                if (
                  item.criteria === 'colour' ||
                  item.criteria === 'color' ||
                  item.criteria === 'Colour' ||
                  item.criteria === 'Color'
                ) {
                  return null
                }
                // if old value does not exist in new, add to delVals
                if (!delOpt?.value?.some((newVal) => newVal === item.value)) {
                  // console.log('deleting', item)
                  delVals.push(item.id)
                }
              })

              // console.log(delVals)
              try {
                // console.log('NEWVALS', newVals)
                // console.log('FORMVALS', values[source])

                // console.log(fieldName)
                await Promise.all(
                  newVals?.map(async (val) => {
                    const newValResp = await dataProvider.create(fieldName, { data: val })

                    // console.log('NVRESP', newValResp)
                    if (
                      val.criteria === 'Colour' ||
                      val.criteria === 'Color' ||
                      val.criteria === 'colour' ||
                      val.criteria === 'color'
                    ) {
                      const index = values[source]
                        .filter(
                          (el) =>
                            el.criteria === 'Colour' ||
                            el.criteria === 'Color' ||
                            el.criteria === 'colour' ||
                            el.criteria === 'color',
                        )
                        .findIndex((item) => {
                          // console.log('ITEM', item)
                          // const newVal = newValResp.data?.value?.replace('\\')
                          const jsonResp = JSON.parse(newValResp.data?.value)
                          if (item.format === 'CMYK') {
                            const cmykItemVal = `${item.value[0]},${item.value[1]},${item.value[2]},${item.value[3]}`
                            const cmykRespVal = `${jsonResp.C},${jsonResp.M},${jsonResp.Y},${jsonResp.K}`
                            // console.log('CMYKITEM', cmykItemVal, 'CMYKRESP', cmykRespVal)
                            return cmykItemVal === cmykRespVal
                          } else if (item.format === 'PANTONE') {
                            // console.log('PANTONEITEM', item.value, 'PANTONERESP', jsonResp.PANTONE)
                            return item.value === jsonResp.PANTONE
                          } else {
                            // console.log('RGBHEXITEM', item.value, 'RGBHEXRESP', jsonResp.HEX)
                            return item.value.substring(1) === jsonResp.HEX
                          }
                        })
                      form.change(`${source}[${index}].id`, newValResp.data.id)
                    }
                  }),
                )

                // console.log('DELVALS', delVals)

                delVals.length > 0
                  ? await dataProvider.deleteMany(fieldName, {
                      ids: delVals,
                    })
                  : []

                // delVals.length > 0 && console.log('DVRESP', delValsResp)

                // console.log('UPDVALS', updVals)
                await Promise.all(
                  updVals?.map(async (val) => {
                    await dataProvider.update(fieldName, {
                      data: {
                        ...val,
                      },
                    })
                    // console.log(updValResp)
                  }),
                )

                // console.log('RETVALS', retainVals)

                // const formVals = []
                // console.log(data.product_criteria)

                // const formCriteria = [...retainVals, ...newValsResp.data]

                // console.log(formCriteria)

                // const uniqueCriteria = []
                // formCriteria?.map((criteria) => {
                //   if (uniqueCriteria.indexOf(criteria.criteria) === -1) {
                //     uniqueCriteria.push(criteria.criteria)
                //   }
                // })

                // console.log(uniqueCriteria)

                // uniqueCriteria.map((criteria) => {
                //   formVals?.push({
                //     criteria: criteria,
                //     value: formCriteria
                //       .filter((qcriteria) => qcriteria.criteria === criteria)
                //       .map((item) => item.value),
                //   })
                // })

                // console.log(formVals)

                // console.log('changing')
                // form.change(oldSource, formCriteria)
                // console.log('changing')
                // form.change(source, formVals)

                notify('Update Complete', { type: 'info' })
                setEditing(false)
              } catch (error) {
                console.log(error)
                notify(error.message, { type: 'warning' })
                setEditing(false)
              }
            }}
            style={{ marginLeft: '30px', marginTop: '16px', maxWidth: '50px', maxHeight: '50px' }}
          >
            <CheckCircleOutlineOutlined fontSize="small" style={{ color: 'FF0000' }} />
          </IconButton>
          <IconButton
            onClick={() => {
              setEditing(false)
            }}
            style={{ marginTop: '16px', maxWidth: '50px', maxHeight: '50px' }}
          >
            <CancelOutlined fontSize="small" />
          </IconButton>
        </Box>
      ) : (
        <IconButton
          onClick={() => {
            setEditing(true)
          }}
          style={{ marginLeft: '30px', marginTop: '16px', maxWidth: '50px', maxHeight: '50px' }}
        >
          <BorderColorIcon fontSize="small" />
        </IconButton>
      )}
    </Box>
  )
}

export const beniColorToString = (config) => {
  // console.log(config)
  if (!config) {
    return ''
  }
  let type = config.type
  if (type == 'HEX' || type == 'RGB') {
    return `{"type":"HEX","HEX":"${config.val.substring(
      1,
    )}","PANTONE":"","R":"","G":"","B":"","C":"","M":"","Y":"","K":""}`
  }
  if (type == 'PANTONE') {
    return `{"type":"PANTONE","HEX":"","PANTONE":"${config.val}","R":"","G":"","B":"","C":"","M":"","Y":"","K":""}`
  }
  // if (type == 'RGB') {
  //   return `{"type":"RGB","HEX":"","PANTONE":"","R":"${config.val.R}","G":"${config.val.G}","B":"${config.val.B}","C":"","M":"","Y":"","K":""}`
  // }
  if (type == 'CMYK') {
    return `{"type":"CMYK","HEX":"","PANTONE":"","R":"","G":"","B":"","C":"${config.val.C}","M":"${config.val.M}","Y":"${config.val.Y}","K":"${config.val.K}"}`
  }
  return 'Unknown color type:' + type
}

export default CriteriaFieldInput
