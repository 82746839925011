import React, { useState, useEffect } from 'react'
import { Box, CircularProgress, Tabs, Tab } from '@material-ui/core'
import { FormWithRedirect, useDataProvider, useNotify, Edit } from 'react-admin'
import { makeStyles } from '@material-ui/core/styles'
import { useFormState } from 'react-final-form'
import FormData from './components/ProductEditForm'
import ProductSummary from './components/ProductSummary'
import SaleableProductsList from '../saleableProducts/SaleableProductsList'
import { roundTo } from '../../utils/utils'

const ProductEdit = (props) => {
  return (
    <Edit {...props} actions={null} component="div" id={props.id} mutationMode="pessimistic">
      <ProductEditForm id={props.id} {...props} />
    </Edit>
  )
}

const useStyles = makeStyles({
  tabPanel: {
    padding: '0px',
    '& .MuiBox-root': {
      padding: '0px',
    },
  },
})

const ProductEditForm = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const { record } = props
  const classes = useStyles()
  // console.log(props)

  const [value, setValue] = useState(0)
  const [config, setConfig] = useState()
  const [materials, setMaterials] = useState([])
  const [imprints, setImprints] = useState([])
  const [packagingTypes, setPackagingTypes] = useState([])
  const [milestones, setMilestones] = useState([])

  useEffect(() => {
    const getDataLists = async () => {
      try {
        const { data: milestonesTemp } = await dataProvider.getList('paymentMilestones', {})
        !abortController.signal.aborted &&
          setMilestones(milestonesTemp.filter((item) => item.type === 'factory'))

        const { data: imprintsTemp } = await dataProvider.getList('imprints', {
          filter: { own: 'imprintTechnique' },
        })
        !abortController.signal.aborted && setImprints(imprintsTemp)

        const { data: materialsTemp } = await dataProvider.getList('materials', {
          filter: { own: '%material%' },
        })
        !abortController.signal.aborted && setMaterials(materialsTemp)

        const { data: packagingTypesTemp } = await dataProvider.getList('packagingStyle', {
          filter: { own: 'packagingStyle' },
        })
        !abortController.signal.aborted && setPackagingTypes(packagingTypesTemp)

        const { data: configTemp } = await dataProvider.globalConfig('globalConfig', {
          fields: [
            'estimated_lead_time_sea',
            'estimated_lead_time_air',
            'estimated_freight_rate_sea',
            'estimated_freight_rate_air',
            'estimated_container_rate_20ft',
            'estimated_container_rate_40ft',
            'estimated_container_rate_40ft_hc',
            'estimated_freight_rate_sea_duties_included',
          ],
        })
        const configVals = {}
        configTemp.global_preferences.forEach(
          (item) => (configVals[item.setting_key] = item.setting_value),
        )
        !abortController.signal.aborted && setConfig(configVals)
      } catch (error) {
        console.log(error)
        notify(error.message, { type: 'warning' })
        return
      }
    }

    const abortController = new AbortController()
    getDataLists()
    return () => {
      abortController.abort()
    }
  }, [])

  if (!record || !config || packagingTypes.length < 1)
    return (
      <Box display="flex" alignItems="center" justifyContent="center">
        <CircularProgress />
      </Box>
    )

  const chosenActivities =
    record.activities?.map((act) => ({
      id: act.id,
      own: 'activity',
      name: act.activity,
    })) || []

  const factoryPrices =
    record?.product_quotes?.length > 0 && record?.product_quotes[0].prices?.length > 0
      ? record?.product_quotes[0]?.prices?.map((item) => ({
          ...(item.id && { id: item.id }),
          unitPrice: roundTo(item.unit_price / 100, 2),
          production_lead_time: item.lead_time,
          quantity: item.quantity,
          landed_air: item.est_landed_cost_air / 100 || null,
          landed_sea: item.est_landed_cost_sea / 100 || null,
          totalCBM: item.total_cbm / 100 || null,
          shipmentWeight: item.shipment_weight / 100 || null,
          totalNumCartons: item.total_cartons,
          totalDuties: item.total_duties / 100 || null,
          freightCostPerPieceAir: item.freight_cost_air_per_piece / 100 || null,
          freightCostPerPieceSea: item.freight_cost_sea_per_piece / 100 || null,
          totalFreightCostAir: item.freight_cost_air / 100 || null,
          totalFreightCostSea: item.freight_cost_sea / 100 || null,
          containers: item.containers,
        }))
      : []

  const otherSpecifications = []
  // const materialOptions =
  //   record?.product_options?.length > 0
  //     ? record.product_options?.filter(
  //         (spec) => !!spec.deleted_at === false && spec.option_value?.option?.name === 'Material',
  //       )
  //     : []

  const uniqueSpecs = []
  if (record?.product_specifications?.length > 0) {
    record.product_specifications.map((spec) => {
      if (uniqueSpecs.indexOf(spec.option_value.option.name) === -1) {
        uniqueSpecs.push(spec.option_value.option.name)
      }
    })
  }

  uniqueSpecs.map((spec) => {
    otherSpecifications?.push({
      name: spec,
      option_value: {
        value:
          record?.product_specifications?.length > 0
            ? record.product_specifications
                .filter(
                  (qspec) => qspec.option_value.option?.name === spec && qspec.deleted_at === null,
                )
                .map((item) => item.option_value?.value)
            : [],
      },
    })
  })

  const oldSpecifications = []

  uniqueSpecs.map((spec) => {
    oldSpecifications?.push({
      name: spec,
      values:
        record?.product_specifications?.length > 0
          ? record.product_specifications
              .filter(
                (qspec) => qspec.option_value.option?.name === spec && qspec.deleted_at === null,
              )
              .map((val) => ({ id: val.id, value: val.option_value.value }))
          : [],
    })
  })
  // const productMaterials = record?.product_specifications?.filter(
  //   (spec) => !!spec.deleted_at === false && spec.option_value?.option?.name === 'Material',
  // )

  const uniqueCustomSpecs = []
  record?.customisations?.map((spec) => {
    if (uniqueCustomSpecs.indexOf(spec?.option_value?.option?.name) === -1) {
      uniqueCustomSpecs.push(spec?.option_value?.option?.name)
    }
  })

  const productCustomisations = uniqueCustomSpecs.map((spec) => {
    return {
      name: spec,
      option_value: {
        value: record?.customisations
          ?.filter(
            (qspec) => qspec.option_value?.option?.name === spec && qspec.deleted_at === null,
          )
          .map((item) => item.option_value?.value),
      },
    }
  })

  const oldCustomisations = uniqueCustomSpecs.map((spec) => {
    return {
      name: spec,
      values: record?.customisations?.filter(
        (qspec) => qspec.option_value?.option?.name === spec && qspec.deleted_at === null,
      ),
    }
  })

  const categories = record?.product_categories
    ?.filter((item) => !item.deleted_at)
    .map((item) => item.category.id)

  const chosenTags = record?.product_tags?.map((item) => item.tag.id)

  const packagingOptions = record.product_quotes[0].packaging_options?.map((opt) => ({
    ...opt,
    costModifier: opt.cost_modifier === 0 ? 0 : opt.cost_modifier / 100 || null,
    cartonDimensions: {
      id: opt.carton_dimensions?.id,
      length: opt.carton_dimensions?.length / 100 || null,
      width: opt.carton_dimensions?.width / 100 || null,
      height: opt.carton_dimensions?.height / 100 || null,
      gross_weight: opt.carton_dimensions?.gross_weight / 100 || null,
    },
    packagingDimensions: {
      id: opt.packaging_dimensions?.id,
      length: opt.packaging_dimensions?.length / 100 || null,
      width: opt.packaging_dimensions?.width / 100 || null,
      height: opt.packaging_dimensions?.height / 100 || null,
      gross_weight: opt.packaging_dimensions?.gross_weight / 100 || null,
    },
    sizes: record.product_quotes[0].product_sizes?.map((sz) => ({
      ...sz,
      relId:
        opt.product_quote_product_size_packagings?.find(
          (el) => el.product_quote_product_size_id === sz.id,
        )?.id || null,
      active: opt.product_quote_product_size_packagings?.some(
        (el) => el.product_quote_product_size_id === sz.id,
      ),
      costModifier: sz.cost_modifier / 100,
    })),
    scmQuotePkgs: opt.scm_quote_packaging_options?.map((scmOpt) => scmOpt.id),
  }))

  const productSizes = record.product_quotes[0].product_sizes.map((size) => ({
    ...size,
    costModifier: size.cost_modifier === 0 ? 0 : size.cost_modifier / 100 || null,
    productDimensions: {
      id: size.dimensions?.id / 100,
      length: size.dimensions?.length / 100 || null,
      width: size.dimensions?.width / 100 || null,
      height: size.dimensions?.height / 100 || null,
      gross_weight: size.dimensions?.gross_weight / 100 || null,
    },
    scmQuoteSizes: size.scm_quote_product_sizes.map((scmSize) => scmSize.id),
  }))

  const configurations = record.product_quotes[0].options?.map((opt) => ({
    ...opt,
    label: opt.name,
    max_unique_selections: opt.max_unique_selections,
    additional_cost_per_unique_choice: opt.additional_cost_per_unique_choice,
    additionalCostPerChoice: opt.additional_cost_per_unique_choice / 100 || 0,
    values: opt.values?.map((val) => ({
      ...val,
      setupCost: val.setup / 100 || 0,
      unitCost: val.cost / 100 || 0,
      components: record.product_quotes[0].components?.map((cmp) => {
        return {
          ...cmp,
          active: val.components?.some((el) => el.component_id === cmp.id),
          relId: val.components?.find((el) => el.component_id === cmp.id)?.id || null,
        }
      }),
    })),
  }))

  const productDecorations = record.product_quotes[0].decorations?.map((el) => ({
    ...el,
    imprintStyles: el.imprint_styles,
    unitCost: el.cost / 100 || 0,
  }))

  const productMaterials = record.materials?.map((el) => ({
    ...el,
    estimatedCost: el.estimated_cost / 100 || 0,
  }))

  // console.log(record)

  const init = {
    ...record,
    productDecorations,
    productMaterials,
    configurations,
    packagingOptions,
    productSizes,
    productCustomisations,
    chosenActivities,
    additional_files: [],
    dutyRate: record?.duty_rate === 0 ? 0 : record?.duty_rate / 100 || null,
    standardColourCostModifier: record?.product_quotes
      ? record?.product_quotes[0]?.standard_colour_cost_modifier === 0
        ? 0
        : record?.product_quotes[0]?.standard_colour_cost_modifier / 100 || null
      : null,
    toolingCost: record?.product_quotes
      ? record?.product_quotes[0]?.tooling_cost === 0
        ? 0
        : record?.product_quotes[0]?.tooling_cost / 100 || null
      : null,
    sampleCost: record?.product_quotes
      ? record?.product_quotes[0]?.sample_cost === 0
        ? 0
        : record?.product_quotes[0]?.sample_cost / 100 || null
      : null,
    customSampleCost: record?.product_quotes
      ? record?.product_quotes[0]?.custom_sample_cost === 0
        ? 0
        : record?.product_quotes[0]?.custom_sample_cost / 100 || null
      : null,
    factoryPrices,
    launch_date:
      record?.product_quotes?.length > 0 && record?.product_quotes[0].scm_quotes?.length > 0
        ? record?.product_quotes[0]?.scm_quotes[0]?.launch_date
        : null,
    quote_notes:
      record?.product_quotes?.length > 0 && record?.product_quotes[0].scm_quotes?.length > 0
        ? record?.product_quotes[0]?.scm_quotes[0]?.notes
        : null,
    clientSamplePrice:
      record?.product_quotes?.length > 0 && record?.product_quotes[0].scm_quotes?.length > 0
        ? record?.product_quotes[0]?.scm_quotes[0]?.client_sample_price
        : null,
    categories: categories || [],
    tags: chosenTags || [],
    // // productMaterials: productMaterials || [],
    otherSpecifications: otherSpecifications || [],
    oldSpecifications: oldSpecifications || [],
    // materialOptions: materialOptions || [],
    oldCustomisations: oldCustomisations || [],
    containerRate20ft:
      record?.product_quotes[0].container_rate_20ft === 0
        ? 0
        : record?.product_quotes[0].container_rate_20ft / 100 ||
          config.estimated_container_rate_20ft / 100 ||
          null,
    containerRate40ft:
      record?.product_quotes[0].container_rate_40ft === 0
        ? 0
        : record?.product_quotes[0].container_rate_40ft / 100 ||
          config.estimated_container_rate_40ft / 100 ||
          null,
    containerRate40ftHC:
      record?.product_quotes[0].container_rate_40ft_hc === 0
        ? 0
        : record?.product_quotes[0].container_rate_40ft_hc / 100 ||
          config.estimated_container_rate_40ft_hc / 100 ||
          null,
    freightRateSea:
      record?.product_quotes[0].freight_rate_sea === 0
        ? 0
        : record?.product_quotes[0].freight_rate_sea / 100 ||
          config.estimated_freight_rate_sea / 100 ||
          null,
    freightRateAir:
      record?.product_quotes[0].freight_rate_air === 0
        ? 0
        : record?.product_quotes[0].freight_rate_air / 100 ||
          config.estimated_freight_rate_air / 100 ||
          null,
    does_sea_freight_include_duties:
      record?.product_quotes[0].does_sea_freight_include_duties ||
      config.estimated_freight_rate_sea_duties_included === 'true' ||
      false,
    svgFileText: record?.product_quotes[0].svg_file,
    // additional_files_type,
  }

  // console.log('init', init)

  // const initVals = useMemo(() => init, [init])

  const validation = (values) => {
    const errors = {}

    // if (values?.catagories?.length < 1 && values?.product_categories?.length < 1) {
    //   errors.categories = 'Min 1 category'
    // }

    !values.name ? (errors.name = 'Name') : null
    // !values.short_description ? (errors.short_description = 'Short Description') : null
    !values.hts_code ? (errors.hts_code = 'HTS Code') : null

    // !values.product_dimensions?.length && !values.productDimensions?.length
    //   ? (errors.productDimensions = { length: 'Product Length' })
    //   : null
    // !values.product_dimensions?.width && !values.productDimensions?.width
    //   ? (errors.productDimensions = { width: 'Product Width' })
    //   : null
    // !values.product_dimensions?.height && !values.productDimensions?.height
    //   ? (errors.productDimensions = { height: 'Product Height' })
    //   : null
    // !values.carton_dimensions?.length && !values.cartonDimensions?.length
    //   ? (errors.cartonDimensions = { length: 'Carton Length' })
    //   : null
    // !values.carton_dimensions?.width && !values.cartonDimensions?.width
    //   ? (errors.cartonDimensions = { width: 'Carton Width' })
    //   : null
    // !values.carton_dimensions?.height && !values.cartonDimensions?.height
    //   ? (errors.cartonDimensions = { height: 'Carton Height' })
    //   : null
    // !values.carton_dimensions?.gross_weight && !values.cartonDimensions?.gross_weight
    //   ? (errors.cartonDimensions = { gross_weight: 'Carton Weight' })
    //   : null

    values.factoryPrices.length < 1 ? (errors.prices = 'Min 1 Price') : null
    !values.product_quotes[0].supplier_id ? (errors.supplier_id = 'Supplier') : null
    // !values.product_quotes[0].packaging_type ? (errors.packaging_type = 'Packaging Type') : null
    // !values.product_quotes[0].pieces_per_carton
    //   ? (errors.pieces_per_carton = 'Pieces per Carton')
    //   : null

    return errors
  }

  if (!config) {
    return <CircularProgress style={{ width: '20px', height: '20px' }} />
  }

  const TabPanel = (props) => {
    const { children, value, index } = props

    // console.log(other)
    return (
      <div
        style={{ padding: '0px' }}
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        className={classes.tabPanel}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </div>
    )
  }

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }
  return (
    <FormWithRedirect
      {...props}
      validate={validation}
      initialValues={init}
      render={(formProps) => (
        <Box>
          <ProductSummary id={props.id} />

          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="simple tabs example"
            style={{ marginTop: '30px', marginBottom: '4px' }}
          >
            <Tab label="Factory Quotes" {...a11yProps(0)} />
            <Tab label="Client Quotes" {...a11yProps(1)} />
            <Tab label="B2B Quotes" {...a11yProps(2)} />
            <Tab label="B2C Quotes" {...a11yProps(2)} />
          </Tabs>
          <TabPanel value={value} index={0} className={classes.tabPanel}>
            <FormData
              packagingTypes={packagingTypes}
              materials={materials}
              imprints={imprints}
              milestones={milestones}
              formProps={formProps}
            />
          </TabPanel>
          <TabPanel value={value} index={1}></TabPanel>
          <TabPanel value={value} index={2}></TabPanel>
          <TabPanel value={value} index={3}>
            <SaleableProductForm packagingOptions={packagingOptions} />
          </TabPanel>
        </Box>
      )}
    />
  )
}

const SaleableProductForm = ({ packagingOptions = [] }) => {
  const { values } = useFormState()

  console.log(values)
  return (
    <SaleableProductsList
      basePath="/saleableProducts"
      resource="saleableProducts"
      showCreate
      productId={values.id}
      prodData={{
        id: values.id,
        name: values.name,
        specifications: values.product_specifications?.map((el) => ({
          specification_id: el.option_value.id,
        })),
        categories: values.product_categories?.map((el) => ({
          category_id: el.category.id,
        })),
        activities: values.chosenActivities.map((el) => ({ activity: el.name })),
        tags: values.product_tags?.map((el) => ({ tag_id: el.tag.id })),
        brand: values.brand,
        hts_code: values.hts_code,
        sku: values.catalogue_sku,
        description: values.short_description,
        long_description: values.long_description,
        dutyRate: values.duty_rate,
        primary_photo_id: values.primary_photo?.id || values.thumbnail_photo?.id,
        files: values.images?.map((el) => ({
          file_id: el.file?.id || el.file_id,
          type: el.type,
        })),
        variants: values.variants,
        quotes: values.product_quotes,
        packagingOptions,
        cartonDimensionsId:
          values.packagingOptions?.length > 0
            ? values.packagingOptions[0].cartonDimensions?.id
            : null,
        productDimensionsId:
          values.productSizes?.length > 0 ? values.productSizes[0].dimensions.id : null,
        packagingDimensionsId:
          values.packagingOptions?.length > 0
            ? values.packagingOptions[0].packagingDimensions?.id
            : null,
        features: values.selling_features,
      }}
    />
  )
}

export default ProductEdit
