import React from 'react'
import {
  // DashboardMenuItem,
  Menu,
  MenuItemLink,
  //  useGetIdentity
} from 'react-admin'
// import ReceiptIcon from '@material-ui/icons/Receipt'
import PeopleIcon from '@material-ui/icons/People'
import PersonIcon from '@material-ui/icons/Person'
import SupervisedUserIcon from '@material-ui/icons/SupervisedUserCircle'
import LabelIcon from '@material-ui/icons/Label'
import ListAltIcon from '@material-ui/icons/ListAlt'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import StoreIcon from '@material-ui/icons/Store'
import PaymentIcon from '@material-ui/icons/Payment'
import ShoppingIcon from '@material-ui/icons/ShoppingBasket'
import FlightIcon from '@material-ui/icons/FlightLand'
import ShopTwoIcon from '@material-ui/icons/ShopTwo'
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd'
import LocalConvenienceStoreIcon from '@material-ui/icons/LocalConvenienceStore'
import StorefrontIcon from '@material-ui/icons/Storefront'
import Settings from '@material-ui/icons/Settings'
import HomeWorkIcon from '@material-ui/icons/HomeWork'
import GroupWorkIcon from '@material-ui/icons/GroupWork'
import GroupWorkOutlinedIcon from '@material-ui/icons/GroupWorkOutlined'
// import LocationCityIcon from '@material-ui/icons/LocationCity'

// import OrgSwitch from './OrgSwitch'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(() => ({
  menuSpacerBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  menuSpacer: {
    width: '65%',
    height: '3px',
    borderRadius: '3px',
    margin: '4px 0px',
    backgroundColor: '#DEDEDE',
  },
}))

const DefaultMenu = (props) => {
  const classes = useStyles()
  // const { identity } = useGetIdentity()

  // if (identity.roles.some((role) => role === 'sca')) {
  //   console.log('sca')
  // } else {
  return (
    <Menu {...props}>
      {/* 
        <span style={{ fontSize: '12px', color: '#656565', margin: '30px 20px 6px' }}>
          PRODUCTS
        </span>
        <MenuItemLink style={{          transition: "transform 0.2s",
          "&:hover": {
            transform: "scale(1.10)",
          },}} to="/quotes" primaryText="Products" leftIcon={<ShoppingIcon />} />
        <MenuItemLink style={{          transition: "transform 0.2s",
          "&:hover": {
            transform: "scale(1.10)",
          },}} to="/requests" primaryText="Quotes in process" leftIcon={<LabelIcon />} />
        <MenuItemLink style={{          transition: "transform 0.2s",
          "&:hover": {
            transform: "scale(1.10)",
          },}} to="/order" primaryText="Purchase Orders" leftIcon={<FlightIcon />} />
        <span style={{ fontSize: '12px', color: '#656565', margin: '30px 20px 6px' }}>
          SALES & INVENTORY
        </span>
        <MenuItemLink style={{          transition: "transform 0.2s",
          "&:hover": {
            transform: "scale(1.10)",
          },}}
          to="/inventory"
          primaryText="Inventory Manager"
          leftIcon={<AssignmentIndIcon />}
        />
        <span style={{ fontSize: '12px', color: '#656565', margin: '30px 20px 6px' }}>
          SETTINGS
        </span>
        <MenuItemLink style={{          transition: "transform 0.2s",
          "&:hover": {
            transform: "scale(1.10)",
          },}} to="/app_users" primaryText="Members" leftIcon={<PeopleIcon />} />
        <MenuItemLink style={{          transition: "transform 0.2s",
          "&:hover": {
            transform: "scale(1.10)",
          },}} to="/addresses" primaryText="Addresses" leftIcon={<ReceiptIcon />} />
        <MenuItemLink style={{          transition: "transform 0.2s",
          "&:hover": {
            transform: "scale(1.10)",
          },}} to="/preferences" primaryText="Preferences" leftIcon={<Settings />} />
        <MenuItemLink style={{          transition: "transform 0.2s",
          "&:hover": {
            transform: "scale(1.10)",
          },}} to="/billing" primaryText="Billing" leftIcon={<PaymentIcon />} />
        <MenuItemLink style={{          transition: "transform 0.2s",
          "&:hover": {
            transform: "scale(1.10)",
          },}} to="/appearance" primaryText="Appearance" leftIcon={<StoreIcon />} /> */}

      {/* <DashboardMenuItem /> */}
      <MenuItemLink
        style={{
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.10)',
          },
        }}
        to="/suppliers"
        primaryText="Suppliers"
        leftIcon={<LocalShippingIcon />}
      />
      <MenuItemLink
        style={{
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.10)',
          },
        }}
        to="/products"
        primaryText="Products"
        leftIcon={<ShoppingIcon />}
      />
      <MenuItemLink
        style={{
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.10)',
          },
        }}
        to="/app_users"
        primaryText="App Users"
        leftIcon={<PersonIcon />}
      />
      <MenuItemLink
        style={{
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.10)',
          },
        }}
        to="/clients"
        primaryText="Clients"
        leftIcon={<PeopleIcon />}
      />
      <div className={classes.menuSpacerBox}>
        <div className={classes.menuSpacer} />
      </div>
      <MenuItemLink
        style={{
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.10)',
          },
        }}
        to="/projects"
        primaryText="Project Requests"
        leftIcon={<HomeWorkIcon />}
      />
      <MenuItemLink
        style={{
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.10)',
          },
        }}
        to="/tasks"
        primaryText="Tasks"
        leftIcon={<ListAltIcon />}
      />
      <MenuItemLink
        style={{
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.10)',
          },
        }}
        to="/consumerOrders"
        primaryText="Consumer Orders"
        leftIcon={<ShopTwoIcon />}
      />
      <MenuItemLink
        style={{
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.10)',
          },
        }}
        to="/order"
        primaryText="Client Orders"
        leftIcon={<FlightIcon />}
      />
      <div className={classes.menuSpacerBox}>
        <div className={classes.menuSpacer} />
      </div>
      <MenuItemLink
        style={{
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.10)',
          },
        }}
        to="/catalogueProducts"
        primaryText="FDR"
        leftIcon={<StoreIcon />}
      />
      <MenuItemLink
        style={{
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.10)',
          },
        }}
        to="/cliqueStock"
        primaryText="Clique Stock"
        leftIcon={<LocalConvenienceStoreIcon />}
      />
      <MenuItemLink
        style={{
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.10)',
          },
        }}
        to="/solidBlanks"
        primaryText="Solid Blanks"
        leftIcon={<StorefrontIcon />}
      />
      <MenuItemLink
        style={{
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.10)',
          },
        }}
        to="/scmQuote"
        primaryText="Client Quotes"
        leftIcon={<AssignmentIndIcon />}
      />
      {(process.env.NODE_ENV !== 'production' ||
        process.env.REACT_APP_BUILD_ENV === 'development') && (
        <MenuItemLink
          style={{
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'scale(1.10)',
            },
          }}
          to="/offer"
          primaryText="Investment Offers"
          leftIcon={<GroupWorkIcon />}
        />
      )}
      <div className={classes.menuSpacerBox}>
        <div className={classes.menuSpacer} />
      </div>
      <MenuItemLink
        style={{
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.10)',
          },
        }}
        to="/configuration"
        primaryText="Configuration"
        leftIcon={<Settings />}
      />
      <div className={classes.menuSpacerBox}>
        <div className={classes.menuSpacer} />
      </div>
      <MenuItemLink
        style={{
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.10)',
          },
        }}
        to="/users"
        primaryText="Admin Users"
        leftIcon={<SupervisedUserIcon />}
      />
      {(process.env.NODE_ENV !== 'production' ||
        process.env.REACT_APP_BUILD_ENV === 'development') && (
        <MenuItemLink
          style={{
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'scale(1.10)',
            },
          }}
          to="/simulator"
          primaryText="Simulator"
          leftIcon={<PaymentIcon />}
        />
      )}
      <div className={classes.menuSpacerBox}>
        <div className={classes.menuSpacer} />
      </div>
      <MenuItemLink
        style={{
          transition: 'transform 0.2s',
          '&:hover': {
            transform: 'scale(1.10)',
          },
        }}
        to="/sourcingRequests"
        primaryText="Sourcing Requests"
        leftIcon={<LabelIcon />}
      />
      {process.env.NODE_ENV !== 'production' && (
        <MenuItemLink
          style={{
            transition: 'transform 0.2s',
            '&:hover': {
              transform: 'scale(1.10)',
            },
          }}
          to="/customOffer"
          primaryText="Custom Offers"
          leftIcon={<GroupWorkOutlinedIcon />}
        />
      )}
    </Menu>
  )
}
// }

export default DefaultMenu
