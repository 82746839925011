/* eslint-disable no-unreachable */
import React, { cloneElement } from 'react'
import { Box, Card } from '@material-ui/core'
import {
  FormWithRedirect,
  useDataProvider,
  useNotify,
  useCreateController,
  CreateContextProvider,
} from 'react-admin'
import Confirm from '../components/Confirm'
import FormData from './components/CreateFormData'
import uploadFile from '../../dataProvider/upload'

const SourcingRequestCreate = (props) => {
  return <CreateForm {...props} />
}

const MyCreate = (props) => {
  const createControllerProps = useCreateController(props)
  const {
    basePath, // deduced from the location, useful for action buttons
    // defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
    record, // empty object, unless some values were passed in the location state to prefill the form
    redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the create callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to create the record
    version, // integer used by the refresh feature
  } = createControllerProps
  return (
    <CreateContextProvider value={createControllerProps}>
      <div>
        {/* <h1>{defaultTitle}</h1> */}
        {cloneElement(props.children, {
          basePath,
          record,
          redirect,
          resource,
          save,
          saving,
          version,
        })}
      </div>
    </CreateContextProvider>
  )
}

const CreateForm = (props) => {
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const debugMode = false

  const handleSave = async (submission) => {
    debugMode && console.log('SUB', submission)
    // try {
    const params = {}

    params.status = '^IN_PROCESS^'

    submission.required_date ? (params.required_date = submission.required_date) : null
    submission.name ? (params.name = submission.name) : null
    submission.target_quantity_min
      ? (params.target_quantity_min = submission.target_quantity_min)
      : null
    submission.target_quantity_max
      ? (params.target_quantity_max = submission.target_quantity_max)
      : null
    submission.targetPrice ? (params.target_price = parseInt(submission.targetPrice * 100)) : null
    submission.notes ? (params.notes = submission.notes) : null
    submission.client_id ? (params.client_id = submission.client_id) : null
    submission.sca_id ? (params.sca_id = submission.sca_id) : null
    submission.delivery_address_id
      ? (params.delivery_address_id = submission.delivery_address_id)
      : null
    submission.destination_post_code
      ? (params.destination_post_code = submission.destination_post_code)
      : null
    submission.market ? (params.market = submission.market) : null

    debugMode && console.log(params)
    if (!!submission.product_criteria?.length > 0 || !!submission.packaging_criteria?.length > 0) {
      params.criteria = { data: [] }
      !!submission.product_criteria?.length > 0 &&
        submission.product_criteria.map((item) => {
          item.value.map((val) => {
            params.criteria.data.push({
              value: val,
              type: '^PRODUCT^',
              criteria: item.criteria,
            })
          })
        })
      !!submission.packaging_criteria?.length > 0 &&
        submission.packaging_criteria.map((item) => {
          item.value.map((val) => {
            params.criteria.data.push({
              value: val,
              type: '^PACKAGING^',
              criteria: item.criteria,
            })
          })
        })
    }
    debugMode && console.log(params)
    if (submission.reference_photo) {
      const file = await uploadFile([submission.reference_photo])
      debugMode && console.log(file)
      params.reference_photo = {
        data: {
          url: file?.data?.files[0]?.url || '',
          filename: submission.reference_photo.name,
        },
        // on_conflict: {
        //   constraint: '^file_filename_key^',
        //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
        // },
      }
    }
    debugMode && console.log(params)
    const images = []
    await Promise.all(
      submission.images?.map(async (item) => {
        const file2 = await uploadFile([item.file])
        images.push({
          file: {
            data: {
              url: file2?.data?.files[0]?.url || '',
              filename: item.file?.name,
            },
          },
          type: '^PHOTO^',
          // on_conflict: {
          //   constraint: '^file_filename_key^',
          //   update_columns: ['^url^', '^size^', '^hash^', '^mime^', '^type^'],
          // },
        })
      }),
    )
    images.length > 0 ? (params.files = { data: images }) : null
    debugMode && console.log(params)

    if (submission.references?.length > 0) {
      params.references = { data: [] }
      submission.references?.map((item) => {
        params.references.data.push({
          ...(item.url && { url: item.url }),
          ...(item.brand && { brand: item.brand }),
          reason: item.reason,
        })
      })
    }
    debugMode && console.log('params', params)

    try {
      const resp = await dataProvider.create('sourcing_request', {
        object: params,
      })

      debugMode && console.log('resp', resp)
      notify('Created Successfully.', { type: 'info' })
    } catch (error) {
      console.log(error)
      notify(error.message.split('.')[0], { type: 'warning' })
    }
  }

  return (
    <MyCreate {...props}>
      <FormWithRedirect
        {...props}
        // validate={(values) => {
        //   const errors = {}
        //   values.quotes?.map((item, index) => {
        // if (!item.estimated_freight_air && !item.estimated_freight_sea) {
        //   errors.quotes = []
        //   errors.quotes[index] = { estimated_freight_sea: 'Required sea or air' }
        //   errors.quotes[index].estimated_freight_air = 'Required sea or air'
        // }
        //   })
        //   return errors
        // }}
        initialValues={{ images: [] }}
        render={(formProps) => (
          <Card>
            <FormData formProps={formProps} />

            <Box display="flex" justifyContent="center" alignItems="center">
              <Confirm
                redirect="/sourcingRequests"
                // redirect={null}
                record={formProps.record}
                basePath={formProps.basePath}
                label="SAVE"
                undoable
                isSave
                handleSubmit={async (submission) => {
                  await handleSave(submission)
                }}
                saving={formProps.saving}
                disabled={formProps.saving || formProps.invalid}
                resource="sourcing_request"
              />
            </Box>
          </Card>
        )}
      />
    </MyCreate>
  )
}

export default SourcingRequestCreate
