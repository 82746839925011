import React from 'react'
import NumberFormat from 'react-number-format'
import { useInput } from 'react-admin'
import { useForm } from 'react-final-form'

const NumberInput = (props) => {
  // const [active, setActive] = useState(false)
  const { className, ...params } = props
  const {
    // eslint-disable-next-line no-unused-vars
    input: { name, onChange, ...rest },
    meta: { touched, error },
    isRequired,
  } = useInput(params)

  const form = useForm()

  // eslint-disable-next-line no-unused-vars
  const { validate, ...others } = params
  // console.log(props)
  return (
    <div className={`${className} MuiFormControl-root MuiTextField-root`}>
      {props.label && (
        <label className="MuiFormLabel-root MuiInputLabel-root MuiInputLabel-formControl MuiInputLabel-animated MuiInputLabel-shrink MuiFormLabel-filled">
          <span>{props.label}</span>
        </label>
      )}
      <div
        className="MuiInputBase-root MuiInput-root MuiInputBase-formControl MuiInput-formControl"
        style={{
          width: '100%',
          borderBottom: '1px solid rgba(0, 0, 0, 0.42)',
        }}
      >
        <NumberFormat
          name={name}
          // onChange={onChange}
          error={touched && !!error ? error : null}
          // helperText={touched && error}
          required={isRequired}
          onValueChange={(values) => form.change(name, values.floatValue)}
          {...others}
          {...rest}
          label={props.label || ''}
          style={{
            width: '80%',
            height: '100%',
            padding: '6px 14px 0px',
            border: '0px',
            font: 'inherit',
            fontSize: '12px',
            margin: '5px 0px',
            backgroundColor: 'rgba(0, 0, 0, 0.00)',
          }}
          renderText={(value, renderProps) => <div {...renderProps}>{value}</div>}
          // onFocus={() => setActive(true)}
          // onBlur={() => setActive(false)}
        />
      </div>
      <p
        className={
          touched && error
            ? 'MuiFormHelperText-root MuiFormHelperText-contained Mui-error'
            : 'MuiFormHelperText-root MuiFormHelperText-contained'
        }
      >
        {touched && error ? 'Required' : null}
      </p>
    </div>
  )
}
export default NumberInput
