import React from 'react'
import {
  Filter,
  List,
  ReferenceInput,
  FunctionField,
  SelectInput,
  SearchInput,
  Datagrid,
  TextField,
  NumberField,
  DateField,
  DateInput,
  TopToolbar,
  CreateButton,
  DeleteButton,
  AutocompleteInput,
} from 'react-admin'
import { Tooltip } from '@material-ui/core'
import { getDisplayName } from '../../utils/utils'

const ListActions = (props) => {
  const { className } = props
  return (
    <TopToolbar className={className}>
      <CreateButton />
    </TopToolbar>
  )
}

export const SourcingRequestFilter = (props) => (
  <Filter {...props}>
    <SearchInput
      // label="Name/Sku"
      source="name"
      alwaysOn
      variant="standard"
      fullWidth
      placeholder="Request Name"
      style={{ minWidth: '200px' }}
    />
    <ReferenceInput
      source="client"
      reference="organisation"
      label="Client"
      sort={{ field: 'name', order: 'ASC' }}
      variant="standard"
      alwaysOn
    >
      <AutocompleteInput
        // className={classes.hideLabelAutoComplete}
        fullWidth
        variant="standard"
        label=""
        optionText={(record) => `${record?.id}. ${record?.name} (${record?.owner?.email})`}
      />
    </ReferenceInput>
    <ReferenceInput
      source="sca"
      reference="users"
      label="SCA"
      filter={{
        status: { _neq: 'DRAFT' },
        roles: { role: { code: { _in: ['sca', 'sca-manager'] } } },
      }}
      style={{ minWidth: '200px' }}
      alwaysOn
      variant="standard"
    >
      <SelectInput optionText={(val) => getDisplayName(val)} fullWidth />
    </ReferenceInput>
    <DateInput
      source="date_gte"
      label="In-Hands date after"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    />
    <DateInput
      source="date_lte"
      label="In-Hands date before"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
    />
    <SelectInput
      source="status"
      label="Status"
      alwaysOn
      variant="standard"
      fullWidth
      style={{ minWidth: '120px' }}
      optionValue="value"
      choices={[
        { name: 'In Review', value: 'IN_REVIEW' },
        { name: 'In Process', value: 'IN_PROCESS' },
        { name: 'Payment', value: 'PAYMENT' },
        { name: 'Completed', value: 'COMPLETED' },
        { name: 'Cancelled', value: 'CANCELLED' },
        { name: 'Rejected', value: 'REJECTED' },
      ]}
    />
  </Filter>
)

const SourcingRequestList = (props) => {
  const { basePath } = props
  return (
    <div>
      <List
        {...props}
        filters={props.basePath === '/sourcingRequests' ? <SourcingRequestFilter /> : null}
        actions={<ListActions />}
        perPage={25}
        sort={{ field: 'id', order: 'DESC' }}
        empty={false}
        bulkActionButtons={false}
      >
        <Datagrid path="sourcingRequests" rowClick="edit">
          <TextField source="id" label="Id" />
          {basePath === '/sourcingRequests' && (
            <FunctionField
              label="Client Name"
              render={(record) => {
                const client = record.client.name
                return client?.length > 40 ? (
                  <Tooltip title={client}>
                    <span>{`${client?.substring(0, 40)}...`}</span>
                  </Tooltip>
                ) : (
                  client
                )
              }}
            />
          )}
          <TextField source="name" label="Name" />
          <FunctionField
            render={(record) => {
              return `$${(record.target_price / 100).toFixed(2)}`
            }}
            label="Target Price"
          />
          <TextField source="target_quantity_min" label="Min Target Quantity" />
          <TextField source="target_quantity_max" label="Max Target Quantity" />
          <FunctionField
            render={(record) => (record.sca ? getDisplayName(record.sca) : 'Unassigned')}
            label="SCA"
          />
          <FunctionField
            render={(record) => (record.payment_confirmed ? 'Yes' : 'No')}
            label="Payment Confirmed"
          />
          <NumberField source="products_aggregate.aggregate.count" label="# Products" />
          <DateField source="created_at" label="Created Date" />
          <DateField source="required_date" label="In-Hands Date" />
          <TextField source="status" label="Status" />
          <DeleteButton undoable={false} />
        </Datagrid>
      </List>
    </div>
  )
}

export default SourcingRequestList
