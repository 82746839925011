import React, { useEffect, useState } from 'react'
import {
  Box,
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
  // Tooltip,
  CircularProgress,
} from '@material-ui/core'
import { useNotify, useDataProvider } from 'react-admin'
import { Skeleton } from '@material-ui/lab'
import { useForm, useFormState } from 'react-final-form'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { cloneDeep } from 'lodash'
import useStyles from '../styles'
import { GSFeed } from '../../components/GSFeed'
import ProductEdit from './ProductEdit'
import SCASelect from './CustomSCASelect'
import ImprintFilesFieldInput from './ImprintFilesFieldInput'
import ReferencesFieldInput from './ReferencesFieldInput'
import RecommendationsFieldInput from './RecommendationsFieldInput'
import CriteriaFieldInput from './CriteriaFieldInput'
import SelectFieldInput from '../../components/fieldInputs/SelectFieldInput'
import TextFieldInput from '../../components/fieldInputs/TextFieldInput'
import DateFieldInput from '../../components/fieldInputs/DateFieldInput'
import NumberFieldInput from '../../components/fieldInputs/NumberFieldInput2'
import ClientSelectFieldInput from '../../components/fieldInputs/ClientSelectFieldInput'
import MultiplePhotoSelect from '../../components/fieldInputs/MultiplePhotoSelect'
import { getDisplayAddress, cloneRecord } from '../../../utils/utils'

const FormData = (props) => {
  const classes = useStyles()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const form = useForm()
  const [imprints, setImprints] = useState([])
  const [categories, setCategories] = useState([])
  const [materials, setMaterials] = useState([])
  const [milestones, setMilestones] = useState([])
  const [config, setConfig] = useState([])
  const [packagingTypes, setPackagingTypes] = useState([])
  const [addresses, setAddresses] = useState([])

  const { sourcing_request_id, isManager, loading } = props

  const { values } = useFormState()

  const setProduct = ({ index, data }) => {
    // console.log(index, data)
    form.change(`products[${index}].product`, data)
  }

  const setDesigns = ({ index = values.designs?.length, data }) => {
    // console.log(index, data)
    const newRecord = cloneRecord(data)

    // console.log(newRecord)
    form.change(`designs[${index}]`, newRecord)
  }

  const removeProduct = (index) => {
    // console.log(index)
    const newProducts = cloneDeep(values.products)
    newProducts.splice(index, 1)
    // console.log(newProducts)
    form.change('products', newProducts)
  }

  const removeDesign = (index) => {
    // console.log(index)
    const newDesigns = cloneDeep(values.designs)
    newDesigns.splice(index, 1)
    // console.log(newDesigns)
    form.change('designs', newDesigns)
  }

  // console.log('EDITFORMDATAPROPS', props)

  useEffect(() => {
    const getClientAddresses = async () => {
      const { data: addressesTemp } = await dataProvider.getList('address', {
        pagination: { page: 1, perPage: 100 },
        sort: { field: 'type', order: 'ASC' },
        filter: { organisation_id: values.client.id },
      })
      setAddresses(addressesTemp)
    }
    values.client_id && getClientAddresses()
  }),
    [values.client_id]

  useEffect(() => {
    const getDataLists = async () => {
      const debugMode = false
      debugMode && console.log('GETTING DATA LISTS')
      try {
        debugMode && console.count('STEP')
        debugMode && console.time('GETTING IMPRINTS')
        const { data: imprintsTemp } = await dataProvider.getList('imprints', {
          filter: { own: 'imprintTechnique' },
        })
        !abortController.signal.aborted && setImprints(imprintsTemp)
        debugMode && console.timeEnd('GETTING IMPRINTS')

        debugMode && console.count('STEP')
        debugMode && console.time('GETTING PACKAGING STYLES')
        const { data: packagingTypesTemp } = await dataProvider.getList('packagingStyle', {
          filter: { own: 'packagingStyle' },
        })
        !abortController.signal.aborted && setPackagingTypes(packagingTypesTemp)
        debugMode && console.timeEnd('GETTING PACKAGING STYLES')

        debugMode && console.count('STEP')
        debugMode && console.time('GETTING CATEGORIES')
        const { data: categoriesTemp } = await dataProvider.getList('categories', {})
        !abortController.signal.aborted && setCategories(categoriesTemp)
        debugMode && console.timeEnd('GETTING CATEGORIES')

        debugMode && console.count('STEP')
        debugMode && console.time('GETTING MATERIALS')
        const { data: materialsTemp } = await dataProvider.getList('materials', {
          filter: { own: '%material%' },
        })
        !abortController.signal.aborted && setMaterials(materialsTemp)
        debugMode && console.timeEnd('GETTING MATERIALS')

        const { data: milestonesTemp } = await dataProvider.getList('paymentMilestones', {})
        !abortController.signal.aborted &&
          setMilestones(milestonesTemp.filter((item) => item.type === 'client'))

        const { data: configTemp } = await dataProvider.globalConfig('globalConfig', {
          fields: [
            'estimated_lead_time_sea',
            'estimated_lead_time_air',
            'estimated_freight_rate_sea',
            'estimated_freight_rate_air',
            'estimated_container_rate_20ft',
            'estimated_container_rate_40ft',
            'estimated_container_rate_40ft_hc',
            'estimated_freight_rate_sea_duties_included',
          ],
        })
        const configVals = {}
        configTemp.global_preferences.forEach(
          (item) => (configVals[item.setting_key] = item.setting_value),
        )
        !abortController.signal.aborted && setConfig(configVals)
      } catch (error) {
        console.log(error)
        notify(error.message.split('.')[0], { type: 'warning' })

        debugMode && console.timeEnd('SAVING DATA TIMER')
        return
      }
    }

    const abortController = new AbortController()
    getDataLists()
    return () => {
      abortController.abort()
    }
  }, [])

  // console.log('SRFORMVALS', values)

  return (
    <Box>
      <Typography className={classes.title}>Sourcing Request Details</Typography>
      <Accordion className={classes.accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.sectionHeader}>Request Details</Typography>
        </AccordionSummary>
        <AccordionDetails style={{ width: '100%' }}>
          <Box display="flex" style={{ width: '100%' }}>
            <Box flex={2}>
              <Box>
                <MultiplePhotoSelect
                  id={values.id}
                  reference="sourcing_request_id"
                  resource="sourcing_request_file"
                  type="^PHOTO^"
                  field="images"
                  primaryField="reference_photo"
                  onClickPreview={async (file) => {
                    await dataProvider.update('sourcing_request', {
                      data: {
                        reference_photo_id: file.file.id,
                        id: values.id,
                      },
                    })
                    form.change('reference_photo', file.file)
                  }}
                  onSave={async (data) => {
                    if (!values.reference_photo?.id) {
                      await dataProvider.update('sourcing_request', {
                        data: {
                          reference_photo_id: data[0].file?.id,
                          id: values.id,
                        },
                      })
                      await form.change('reference_photo', {
                        id: data[0].file?.id,
                        filename: data[0].file?.filename,
                        url: data[0].file.url,
                      })
                    }
                  }}
                />
              </Box>
              <TextFieldInput
                variant="standard"
                fullWidth
                field="name"
                resource="sourcing_request"
                id={values.id}
                label="Product name:"
                value={values.name}
                className={classes.fieldInputLeft}
              />

              <TextFieldInput
                variant="standard"
                fullWidth
                field="description"
                className={classes.fieldInputWide}
                resource="sourcing_request"
                id={values.id}
                multiline
                style={{
                  alignItems: 'start',
                }}
                label="Description:"
                value={values.description}
              />
              <ReferencesFieldInput
                source="references"
                resource="sourcing_request_reference"
                label="References"
              />
              <Box mt="10px" display="flex" flexDirection="column">
                <SCASelect
                  source="sca_id"
                  resource="sourcing_request"
                  label="SCA"
                  reference="users"
                  id={values.id}
                />
                <ClientSelectFieldInput
                  field="client_id"
                  resource="sourcing_request"
                  id={values.id}
                  className={classes.fieldInputLeft}
                  onSave={async () => {
                    form.change('delivery_address_id', null)
                    form.change('destination_post_code', null)
                    await dataProvider.update('sourcing_request', {
                      data: {
                        id: values.id,
                        delivery_address_id: null,
                        destination_post_code: null,
                      },
                    })
                  }}
                />
                <span style={{ fontSize: '10px' }}>
                  Changing this will remove address details below.
                </span>
              </Box>
              <Box display="flex" mt="10px">
                <DateFieldInput
                  variant="standard"
                  fullWidth
                  field="required_date"
                  formField="required_date"
                  resource="sourcing_request"
                  id={values.id}
                  label="Required in-hands date:"
                  className={classes.fieldInputLeft}
                />

                <NumberFieldInput
                  className={classes.fieldInput}
                  label="Target price:"
                  value={values?.targetPrice}
                  resource="sourcing_request"
                  field="target_price"
                  formField="targetPrice"
                  defaultValue={0}
                  decimalScale={2}
                  fixedDecimalScale
                  parseMultiplier={100}
                  prefix="$ "
                  id={values.id}
                />
                <NumberFieldInput
                  className={classes.fieldInput}
                  label="Minimum target quantity:"
                  value={values?.target_quantity_min}
                  resource="sourcing_request"
                  field="target_quantity_min"
                  formField="target_quantity_min"
                  defaultValue={0}
                  decimalScale={0}
                  fixedDecimalScale
                  id={values.id}
                />
                <NumberFieldInput
                  className={classes.fieldInput}
                  label="Maximum target quantity:"
                  value={values?.target_quantity_max}
                  resource="sourcing_request"
                  field="target_quantity_max"
                  formField="target_quantity_max"
                  defaultValue={0}
                  decimalScale={0}
                  fixedDecimalScale
                  id={values.id}
                />
              </Box>
              <Box mt="30px">
                <span>Product Criteria</span>
                <CriteriaFieldInput
                  referenceId={values.id}
                  referenceType="PRODUCT"
                  fieldName="sourcing_request_criteria"
                  source="productCriteria"
                  oldSource="oldProductCriteria"
                  oldData={values.oldProductCriteria}
                />
                <span>Packaging Criteria</span>
                {packagingTypes.length > 0 ? (
                  <SelectFieldInput
                    variant="standard"
                    field="packaging_type"
                    resource="sourcing_request"
                    id={values.id}
                    label="Requested packaging type"
                    choices={packagingTypes}
                    value={values.packaging_type}
                    optionText="name"
                    optionValue="value"
                    style={{ width: '240px', minWidth: '240px' }}
                  />
                ) : (
                  <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
                )}
                <CriteriaFieldInput
                  referenceId={values.id}
                  referenceType="PACKAGING"
                  fieldName="sourcing_request_criteria"
                  source="packagingCriteria"
                  oldSource="oldPackagingCriteria"
                  oldData={values.oldPackagingCriteria}
                />
              </Box>
              <Box display="flex" mt="30px">
                <Box flex={1}>
                  <ImprintFilesFieldInput
                    resource="sourcing_request_file"
                    field="product_imprints"
                    label="Product imprints"
                    type="PRODUCT_IMPRINT"
                  />
                  <TextFieldInput
                    variant="standard"
                    fullWidth
                    field="notes"
                    className={classes.fieldInputWide}
                    resource="sourcing_request"
                    id={values.id}
                    multiline
                    style={{
                      alignItems: 'start',
                    }}
                    label="Product imprint notes:"
                    // validate={required()}
                    value={values.notes}
                  />
                </Box>
                <Box flex={1}>
                  <ImprintFilesFieldInput
                    resource="sourcing_request_file"
                    field="packaging_imprints"
                    label="Packaging imprints"
                    type="PACKAGING_IMPRINT"
                  />
                  <TextFieldInput
                    variant="standard"
                    fullWidth
                    field="packaging_notes"
                    className={classes.fieldInputWide}
                    resource="sourcing_request"
                    id={values.id}
                    multiline
                    style={{
                      alignItems: 'start',
                    }}
                    label="Packaging imprint notes:"
                    // validate={required()}
                    value={values.packaging_notes}
                  />
                </Box>
              </Box>
              {values.client_id && addresses.length > 0 ? (
                <SelectFieldInput
                  variant="standard"
                  field="delivery_address_id"
                  resource="sourcing_request"
                  id={values.id}
                  label="Delivery address"
                  choices={addresses}
                  value={values.delivery_address_id}
                  optionText={(record) => getDisplayAddress(record)}
                  // optionValue="value"
                  style={{ width: '240px', minWidth: '240px' }}
                />
              ) : (
                <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
              )}
              <TextFieldInput
                variant="standard"
                fullWidth
                field="destination_post_code"
                resource="sourcing_request"
                id={values.id}
                label="Destination post code:"
                // validate={required()}
                value={values.destination_post_code}
                className={classes.fieldInputLeft}
              />
            </Box>
            <Box flex={1} p="0px 30px">
              <GSFeed
                feedType="sourcing_request"
                id={`${sourcing_request_id}`}
                values={values}
                data={{
                  sourcingRequestId: sourcing_request_id,
                  quotes: values.products,
                  report: values.feedback,
                  organisation: values.client,
                }}
              />
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      <Accordion className={classes.accordionStyle}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
        >
          <Typography className={classes.sectionHeader}>
            {`Report Files, Additional Content (Photo's and Video's`})
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Box display="flex" flexDirection="column">
            <Box mt="30px">
              <TextFieldInput
                variant="standard"
                fullWidth
                field="feedback"
                className={classes.fieldInputWide}
                resource="sourcing_request"
                id={values.id}
                multiline
                style={{
                  alignItems: 'start',
                }}
                label="Our recommendations:"
                // validate={required()}
                value={values.feedback}
              />

              <Box mt="20px">
                <RecommendationsFieldInput
                  source="recommendations"
                  resource="sourcing_request_recommendation"
                  label="Recommendations"
                />
              </Box>
              <Box mt="30px">
                <ImprintFilesFieldInput
                  resource="sourcing_request_file"
                  field="report_files"
                  label="Recommendation attachments"
                  type="REPORT"
                />
              </Box>
              <Box mt="30px">
                <ImprintFilesFieldInput
                  resource="sourcing_request_file"
                  field="additional_files"
                  label={`Additional content (photos & videos)`}
                  type="MEDIA"
                />
              </Box>
            </Box>
          </Box>
        </AccordionDetails>
      </Accordion>
      {!loading ? (
        <ProductEdit
          client_id={values.client_id}
          categories={categories}
          packagingTypes={packagingTypes}
          materials={materials}
          imprints={imprints}
          milestones={milestones}
          config={config}
          setProduct={setProduct}
          setDesigns={setDesigns}
          removeProduct={removeProduct}
          products={values.products}
          designs={values.designs}
          removeDesign={removeDesign}
          sourcing_request_id={sourcing_request_id}
          isManager={isManager}
          srForm={form}
          srFormVals={values}
        />
      ) : (
        <CircularProgress style={{ width: '20px', height: '20px' }} />
      )}

      {!values.payment_confirmed && values.status !== 'COMPLETED' && (
        <Typography className={classes.productsWarning}>
          PAYMENT NOT RECEIVED OR REQUEST CANCELLED
        </Typography>
      )}
    </Box>
  )
}

export default FormData
