import React from 'react'
import { Box, Button, Tooltip } from '@material-ui/core'
// import { uniqueId } from 'lodash'
import { useForm, useFormState } from 'react-final-form'
// import Skeleton from '@material-ui/lab/Skeleton'
import {
  // required,
  // NumberInput,
  useRedirect,
  useDataProvider,
  // useNotify,
  // useGetIdentity,
} from 'react-admin'
import { ApolloProvider } from '@apollo/client'
import WarningIcon from '@material-ui/icons/Warning'
import NewClient from '../../../dataProvider/hasura/buildApolloClient'
// import { saveFactoryQuote } from '../common/utils'
// import { handleSave } from '../common/utils'
// import Confirm from '../../components/Confirm'
import ClientQuoteCreate from './ClientQuoteCreate'
import useStyles from '../styles'
import FactoryQuoteCalculator from '../../products/components/FactoryQuoteCalculator'
import AdditionalFilesFieldInput from '../../components/fieldInputs/AdditionalFilesFieldInput'
import MultiplePhotoSelect from '../../components/fieldInputs/MultiplePhotoSelect'
import AutocompleteSpecificationArrayFieldInput from '../../components/fieldInputs/AutocompleteSpecificationArrayFieldInput'
import AutocompleteArrayFieldInput from '../../components/fieldInputs/AutocompleteArrayFieldInput'
// import MultipleColourSelect from '../../components/fieldInputs/MultipleColourSelect'
// import ChipFieldInput from '../../components/fieldInputs/ChipFieldInput'
import SpecificationArrayFieldInput from '../../components/fieldInputs/SpecificationArrayFieldInput'
// import DimensionsTableFieldInput from '../../components/fieldInputs/DimensionsTableFieldInput'
import TextFieldInput from '../../components/fieldInputs/TextFieldInput'
import DateFieldInput from '../../components/fieldInputs/DateFieldInput'
import NumberFieldInput from '../../components/fieldInputs/NumberFieldInput'
// import SelectFieldInput from '../../components/SelectFieldInput'
import BooleanFieldInput from '../../components/fieldInputs/BooleanFieldInput'
import TagSelectWithCreate from '../../components/fieldInputs/TagSelectWithCreate'
import SupplierSelectWithCreate from '../../components/fieldInputs/SupplierSelectWithCreate'
// import CustomisationsTable from '../../products/components/CustomisationsTable'
import ProductSizesTable from '../../components/ProductSizesTable'
import ProductOptionsForm from '../../components/ProductOptionsForm'

const FormData = (props) => {
  // console.log('FDPROPS', props)
  const {
    packagingTypes = [],
    title,
    // setClientQuoteProductData,
    // setProduct,
    // productData = {},
    // setProductData,
    // setProductId,
    // index,
    materials = [],
    imprints = [],
    milestones = [],
    config = {},
    clientId,
    isManager = false,
    // formProps,
    // srForm,
  } = props
  const classes = useStyles()
  const form = useForm()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()
  // const notify = useNotify()
  // const identity = useGetIdentity()
  const { values, errors } = useFormState()

  const localToken = localStorage.getItem('token')

  const token = typeof localToken === 'string' ? localToken : localToken.access_token
  const client = NewClient(token)

  // Object.keys(errors).length > 0 && console.log('FORMERRORS', errors)

  // console.log('PDFORMVALS:', values)

  const branchProducts =
    values.branch_products?.length > 0
      ? values.branch_products
      : values.root_product?.branch_products

  return (
    <Box className={classes.outerBox}>
      {values.product_quotes[0].scm_quotes?.length > 0 ? (
        <div>
          <ClientQuoteCreate
            milestones={milestones}
            config={config}
            basePath="/catalogueProducts"
            resource="scmQuote"
            id={values.product_quotes[0].scm_quotes[0].id}
            factoryPrices={values.factoryPrices}
            isManager={isManager}
            clientId={clientId}
            clientQuoteProductData={values}
            product_quote_id={
              values.product_quotes?.length > 0 ? values.product_quotes[0].id : null
            }
          />
          <div
            style={{
              margin: '60px 10px',
              height: '2px',
              backgroundColor: 'darkgray',
            }}
          />
        </div>
      ) : (
        <Box display="flex" alignItems="center" justifyContent="center">
          <Button
            disabled={Object.keys(errors).length > 0}
            style={{
              color: 'white',
              backgroundColor: Object.keys(errors).length > 0 ? '#AAAAAA' : '#00B3C3',
              // height: '40px',
              marginTop: '10px',
              padding: '10px',
            }}
            onClick={async () => {
              // console.log('here', values, clientId)
              const resp = await dataProvider.create('scm_quote', {
                data: {
                  product_quote_id: values.product_quotes[0].id,
                  client_id: clientId,
                  container_rate_20ft: Math.round(values.containerRate20ft * 100),
                  container_rate_40ft: Math.round(values.containerRate40ft * 100),
                  container_rate_40ft_hc: Math.round(values.containerRate40ftHC * 100),
                  freight_rate_air: Math.round(values.freightRateAir * 100),
                  freight_rate_sea: Math.round(values.freightRateSea * 100),
                  container_capacity_20ft: values.product_quotes[0].container_capacity_20ft,
                  container_capacity_40ft: values.product_quotes[0].container_capacity_40ft,
                  container_capacity_40ft_hc: values.product_quotes[0].container_capacity_40ft_hc,
                  does_sea_freight_include_duties: values.does_sea_freight_include_duties,
                  duty_rate: Math.round(values.dutyRate * 100),
                  product_components: {
                    data: values.product_quotes[0].components.map((el) => ({
                      name: el.name || '',
                    })),
                  },
                },
              })

              const pq = { ...values.product_quotes[0] }

              pq.scm_quotes.push(resp.data)

              form.change('product_quotes[0]', pq)
            }}
          >
            Add Client Quote
          </Button>
          {Object.keys(errors).length > 0 ? (
            <Tooltip title={Object.values(errors).join(', ')}>
              <WarningIcon style={{ marginTop: '10px', marginLeft: '20px' }} />
            </Tooltip>
          ) : null}
        </Box>
      )}

      {title && (
        <Box display="flex" justifyContent="space-between">
          <span className={classes.title}>{title}</span>
        </Box>
      )}

      <Box display="flex">
        <Box flex={1}>
          <MultiplePhotoSelect
            id={values.id}
            reference="product_id"
            resource="product_file"
            type="^ADDITIONAL_PHOTO^"
            field="images"
            primaryField="primary_photo"
            onClickPreview={async (file) => {
              await dataProvider.update('product', {
                data: {
                  thumbnail_photo_id: file.file.id,
                  primary_photo_id: file.file.id,
                  id: values.id,
                },
              })
              form.change('primary_photo', file.file)
              form.change('thumbnail_photo', file.file)
            }}
            onSave={async (data) => {
              if (!values.primary_photo?.id) {
                await dataProvider.update('product', {
                  data: {
                    thumbnail_photo_id: data[0].file?.id,
                    primary_photo_id: data[0].file?.id,
                    id: values.id,
                  },
                })
                await form.change('primary_photo', {
                  id: data[0].file?.id,
                  filename: data[0].file?.filename,
                  url: data[0].file.url,
                })
                await form.change('thumbnail_photo', {
                  id: data[0].file?.id,
                  filename: data[0].file?.filename,
                  url: data[0].file.url,
                })
              }
            }}
          />
        </Box>
        <Box flex={2} ml="50px">
          <TextFieldInput
            variant="standard"
            // fullWidth
            field="name"
            resource="product"
            id={values.id}
            label="Product name:"
            // validate={required()}
            value={values.name}
          />
          <AutocompleteArrayFieldInput
            variant="standard"
            fullWidth
            reference="categories"
            fieldLabel="Categories"
            elementName="category"
            // validate={required()}
            data={values.categories}
            oldData={values.product_categories}
            resourceName="product_categories"
            fieldName="category_id"
            referenceField="product_id"
            referenceId={values.id}
          />
          {/* <span className={classes.productSKU}>124-373</span> */}
          <TagSelectWithCreate
            variant="standard"
            fullWidth
            reference="tags"
            fieldLabel="Tags"
            // validate={required()}
            data={values.tags}
            oldData={values.product_tags}
            resourceName="product_tags"
            fieldName="tag_id"
            referenceField="product_id"
            referenceId={values.id}
          />
          <TextFieldInput
            variant="standard"
            // fullWidth
            field="brand"
            resource="product"
            id={values.id}
            label="Brand:"
            // validate={required()}
            value={values.brand}
          />

          <TextFieldInput
            variant="standard"
            // fullWidth
            field="short_description"
            resource="product"
            id={values.id}
            label="Short description:"
            // validate={required()}
            value={values.short_description}
          />
          <Box display="flex">
            <Box flex={1}>
              <SupplierSelectWithCreate
                // validate={required()}
                label="Supplier"
                optionText={(value) => {
                  return value?.official_verification_link
                    ? value?.name
                    : `${value?.name} (Unverified)`
                }}
                source={'product_quotes[0].supplier_id'}
              />
            </Box>
            <Box flex={3} />
          </Box>
          {props.hideCatalogue ? null : (
            <TextFieldInput
              variant="standard"
              fullWidth
              field="catalogue_sku"
              resource="product"
              id={values.id}
              label="Catalogue SKU:"
              // validate={required()}
              value={values.catalogue_sku}
              className={classes.fieldInputLeftNarrow}
            />
          )}
          <span className={classes.labelText}>Specifications</span>
          <ApolloProvider client={client}>
            <SpecificationArrayFieldInput
              variant="standard"
              fullWidth
              fieldLabel="Other specifications"
              resourceName="product_specifications"
              source="otherSpecifications"
              fieldName="product_specifications"
              referenceField="product_id"
              referenceId={values.id}
              oldDataField="oldSpecifications"
              onConflict={{
                on_conflict: {
                  constraint: '^product_specifications_product_id_specification_id_key^',
                  update_columns: ['^product_id^', '^specification_id^'],
                },
              }}
            />
          </ApolloProvider>
        </Box>
      </Box>

      <FactoryQuoteCalculator />
      <Box display="flex" mt="30px">
        <DateFieldInput
          variant="standard"
          fullWidth
          field="valid_date"
          formField="product_quotes[0].valid_date"
          resource="product_quote"
          id={values.product_quotes[0].id}
          label="Factory quote valid until:"
          className={classes.fieldInputLeftNarrow}
          // validate={required()}
          // value={values.product_quotes[0].valid_date}
        />

        <TextFieldInput
          variant="standard"
          fullWidth
          field="hts_code"
          resource="product"
          id={values.id}
          label="HTS code:"
          // validate={required()}
          value={values.hts_code}
          className={classes.fieldInputLeftNarrow}
        />
        {/* <NumberFieldInput
          className={classes.fieldInputLeftNarrow}
          label="Duty rate:"
          value={values?.dutyRate}
          resource="product"
          field="duty_rate"
          defaultValue={0}
          decimalScale={2}
          fixedDecimalScale
          parseMultiplier={100}
          suffix=" %"
          id={values.id}
          // onSave={() => saveFactoryQuote({ values, dataProvider })}
        /> */}
      </Box>
      <Box display="flex" mt="30px">
        <NumberFieldInput
          className={classes.fieldInputLeftNarrow}
          label="Tooling cost:"
          value={values?.toolingCost}
          resource="product_quote"
          field="tooling_cost"
          defaultValue={0}
          decimalScale={2}
          fixedDecimalScale
          parseMultiplier={100}
          prefix="$ "
          id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
        />
        <NumberFieldInput
          className={classes.fieldInputLeftNarrow}
          label="Tooling lead time:"
          value={
            values?.product_quotes?.length > 0 ? values.product_quotes[0].tooling_lead_time : null
          }
          resource="product_quote"
          field="tooling_lead_time"
          defaultValue={0}
          decimalScale={0}
          fixedDecimalScale
          suffix=" days"
          id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
        />
        <NumberFieldInput
          className={classes.fieldInputLeftNarrow}
          label="Sample cost:"
          value={values?.sampleCost}
          resource="product_quote"
          field="sample_cost"
          defaultValue={0}
          decimalScale={2}
          fixedDecimalScale
          parseMultiplier={100}
          prefix="$ "
          id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
        />
        <NumberFieldInput
          className={classes.fieldInputLeftNarrow}
          label="Custom sample cost:"
          value={values?.customSampleCost}
          resource="product_quote"
          field="custom_sample_cost"
          defaultValue={0}
          decimalScale={2}
          fixedDecimalScale
          parseMultiplier={100}
          prefix="$ "
          id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
        />
        <NumberFieldInput
          className={classes.fieldInputLeftNarrow}
          label="Sample lead time:"
          value={
            values?.product_quotes?.length > 0 ? values.product_quotes[0].sample_lead_time : null
          }
          resource="product_quote"
          field="sample_lead_time"
          defaultValue={0}
          decimalScale={0}
          fixedDecimalScale
          suffix=" days"
          id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
        />
      </Box>

      {packagingTypes?.length > 0 ? <ProductSizesTable packagingTypes={packagingTypes} /> : null}

      <ProductOptionsForm materials={materials} imprints={imprints} />

      {/* <Box mt="30px">
        <span className={classes.sectionHeader}>Standard color options</span>
        <div style={{ width: '100%', height: '20px' }} />
        {values.product_quotes?.length > 0 &&
          values.product_quotes[0].components?.map((comp, index) => (
            <Box
              key={`$component${comp.id}`}
              style={{
                border: 'lightgray solid 1px',
                display: 'flex',
                alignItems: 'center',
                maxWidth: '80vw',
                flexWrap: 'wrap',
              }}
            >
              <span style={{ minWidth: '220px', fontSize: '12px' }}>Component: {comp.name}</span>
              <MultipleColourSelect
                variant="standard"
                fullWidth
                field={`product_quotes[0].components[${index}].colours`}
                resource="component_colours"
                parentField="component_id"
                parentId={comp.id}
                // deleteField="delOpts"
                label=""
                parentForm={form}
                // validate={required()}
                value={
                  values.product_quotes?.length > 0 &&
                  values.product_quotes[0].components[index].colours
                }
              />
            </Box>
          ))}
        <div style={{ width: '100%', height: '20px' }} />
        <NumberFieldInput
          label="Standard Colors MOQ"
          value={
            values?.product_quotes?.length > 0 ? values.product_quotes[0].standard_colour_moq : null
          }
          resource="product_quote"
          field="standard_colour_moq"
          id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
        />
        <div style={{ width: '100%', height: '20px' }} />
        <NumberFieldInput
          className={classes.fieldInputLeftNarrow}
          label="Standard Colors Cost Modifier:"
          value={values?.standardColourCostModifier}
          resource="product_quote"
          field="standard_colour_cost_modifier"
          defaultValue={0}
          decimalScale={2}
          fixedDecimalScale
          parseMultiplier={100}
          prefix="$ "
          id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
        />
        <div style={{ width: '100%', height: '20px' }} /> */}
      <BooleanFieldInput
        field="allow_order_splitting_by_colour"
        resource="product_quote"
        id={values.product_quotes[0].id}
        label="Allow order splitting by colors?"
      />
      {/* </Box> */}

      {/* <Box mt="30px">

        <span className={classes.labelText}>Customizations</span>
        <CustomisationsTable />
      </Box> */}

      <span className={classes.labelText}>Material(s)</span>

      <AutocompleteSpecificationArrayFieldInput
        variant="standard"
        fullWidth
        filter={{ own: '%material%' }}
        reference="material"
        fieldLabel="Materials"
        elementName="option_value"
        optionName="Material"
        // validate={required()}
        data={values.productMaterials}
        oldData={values.product_specifications}
        resourceName="productMaterials"
        fieldName="product_specifications"
        referenceField="product_id"
        referenceId={values.id}
        onConflict={{
          on_conflict: {
            constraint: '^product_specifications_product_id_specification_id_key^',
            update_columns: ['^product_id^', '^specification_id^'],
          },
        }}
      />
      {/* <span className={classes.labelText}>Material options</span>
      <AutocompleteSpecificationArrayFieldInput
        variant="standard"
        fullWidth
        filter={{ own: '%material%' }}
        reference="material"
        fieldLabel="Materials"
        elementName="option_value"
        optionName="Material"
        // validate={required()}
        data={values.materialOptions}
        oldData={values.product_options}
        resourceName="materialOptions"
        fieldName="product_options"
        referenceField="product_id"
        referenceId={values.id}
        onConflict={{
          on_conflict: {
            constraint: '^product_options_product_id_specification_id_key^',
            update_columns: ['^product_id^', '^specification_id^'],
          },
        }}
      /> */}

      {/* <Box mt="30px">
        <span className={classes.labelText}>Sizes</span>

        <Box mt="20px" mb="10px" display="flex" position="relative">
          <Box flex={1} p="20px">
            <ChipFieldInput
              variant="standard"
              fullWidth
              fieldLabel="Sizes"
              elementName="option_value"
              optionName="Size"
              // validate={required()}
              data={values.sizeOptions}
              oldData={values.product_options?.filter(
                (spec) => !!spec.deleted_at === false && spec.option_value?.option?.name === 'Size',
              )}
              resourceName="sizeOptions"
              fieldName="product_options"
              referenceField="product_id"
              referenceId={values.id}
              parentForm={form}
            />
          </Box>
          <Box flex={1} />
        </Box>
      </Box> */}
      {/* <Box mt="30px">
        <span className={classes.labelText}>Customizations</span>
        <ApolloProvider client={client}>
          <SpecificationArrayFieldInput
            variant="standard"
            fullWidth
            fieldLabel="Customizations"
            resourceName="productCustomisations"
            fieldName="product_customisations"
            referenceField="product_id"
            referenceId={values.id}
            oldDataField="oldCustomisations"
          />
        </ApolloProvider>
      </Box> */}
      {/* <Box mt="30px">
        <span className={classes.labelText}>Product dimensions</span>
        <ApolloProvider client={client}>
          <DimensionsTableFieldInput
            resourceName="productDimensions"
            referenceField="product_dimensions"
            resourceReference="product_dimensions_id"
            referenceId={values.id}
          />
        </ApolloProvider>
      </Box>
      <Box mt="30px" display="flex" flexDirection="column">
        <span className={classes.labelText}>Packaging type and dimensions</span>
        {packagingTypes.length > 0 ? (
          <SelectFieldInput
            variant="standard"
            field="packaging_type"
            resource="product_quote"
            id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
            label="Packaging type"
            choices={packagingTypes}
            value={
              values?.product_quotes?.length > 0 ? values.product_quotes[0]?.packaging_type : null
            }
            optionText="name"
            optionValue="value"
            style={{ width: '240px', minWidth: '240px' }}
          />
        ) : (
          <Skeleton style={{ width: '240px', height: '62px' }} animation="wave" />
        )}
        <ApolloProvider client={client}>
          <DimensionsTableFieldInput
            resourceName="packagingDimensions"
            referenceField="packaging_dimensions"
            resourceReference="packaging_dimensions_id"
            referenceId={values.id}
          />
        </ApolloProvider>
      </Box>
      <Box mt="30px" display="flex" flexDirection="column">
        <span className={classes.labelText}>Carton quantities</span>
        <Box mt="30px" display="flex">
          <NumberFieldInput
            label="Cartons per pallet"
            value={
              values?.product_quotes?.length > 0
                ? values.product_quotes[0].cartons_per_pallet
                : null
            }
            resource="product_quote"
            field="cartons_per_pallet"
            id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
          />
          <NumberFieldInput
            label="Cartons per container (20ft)"
            value={
              values?.product_quotes?.length > 0
                ? values.product_quotes[0].cartons_per_container_20ft
                : null
            }
            resource="product_quote"
            field="cartons_per_container_20ft"
            id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
          />
          <NumberFieldInput
            label="Cartons per container (40ft)"
            value={
              values?.product_quotes?.length > 0
                ? values.product_quotes[0].cartons_per_container_40ft
                : null
            }
            resource="product_quote"
            field="cartons_per_container_40ft"
            id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
          />
          <NumberFieldInput
            label="Cartons per container (40ft HQ)"
            value={
              values?.product_quotes?.length > 0
                ? values.product_quotes[0].cartons_per_container_40ft_hc
                : null
            }
            resource="product_quote"
            field="cartons_per_container_40ft_hc"
            id={values?.product_quotes?.length > 0 ? values.product_quotes[0].id : null}
          />
        </Box>
      </Box> */}
      <Box mt="30px" display="flex" flexDirection="column">
        <TextFieldInput
          variant="standard"
          fullWidth
          field="notes"
          className={classes.textInputFull}
          resource="product"
          id={values.id}
          multiline
          style={{
            alignItems: 'start',
          }}
          label="Product notes:"
          // validate={required()}
          value={values.notes}
        />
      </Box>

      <AdditionalFilesFieldInput
        variant="standard"
        fullWidth
        field="additional_files"
        resource="product"
        id={values.id}
        label=""
        value={values.additional_files}
      />

      <Box mt="30px" display="flex" flexDirection="column">
        <span className={classes.labelText}>Product Family</span>
        <Box display="flex" className={classes.relatedProductsContainer}>
          {values.id && values.root_product_id && values.root_product_id !== values.id && (
            <Box key={`root${values.root_product_id}`} className={classes.relatedProductsImageBox}>
              <span className={classes.baseProductLabel}>Base Product</span>
              <img
                src={values.root_product?.thumbnail_photo?.url}
                className={classes.relatedProductsImage}
                alt=""
                // onClick={(e) => console.log(values.root_product_id)}
                onClick={() => redirect(`/products/${values.root_product_id}`)}
              />
              <span>{values.root_product?.name}</span>
            </Box>
          )}
          {branchProducts?.map((item) => (
            <Box key={`branch${item.id}`} className={classes.relatedProductsImageBox}>
              <img
                src={item.thumbnail_photo?.url}
                alt=""
                className={classes.relatedProductsImage}
                onClick={() => redirect(`/products/${item.id}`)}
              />
              <span>{item.name}</span>
            </Box>
          ))}
        </Box>
      </Box>
      {/* <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        mt="30px"
      >
        <Confirm
          // redirect='/search'
          // redirect="show"
          // redirect="/products"
          redirect={null}
          record={formProps.record}
          basePath={formProps.basePath}
          label="SAVE PRODUCT"
          undoable
          invalid={formProps.invalid}
          handleSubmit={async (newRecord) => {
            const resp = await handleSave({
              submission: newRecord,
              type: 'save',
              dataProvider,
              notify,
              record: productData,
              sourcing_request_id,
              identity,
            })
            // console.log('SAVERESP', resp)
            const newProduct = { ...newRecord, id: resp.id }
            // form.change('product_quotes[0].id', resp.product_quote_id)
            // console.log(newProduct)
            await setProduct({ index, data: newProduct })
            // setProductData(newProduct)
            await setProductId(resp.id)
            // console.log(productData)
            form.restart(productData)
            // srForm.change(`products[${index}].id`, resp)
          }}
          saving={formProps.saving}
          disabled={
            formProps.saving
            // categories.length < 1 ||
            //   milestones.length < 1 ||
            // materials.length < 1
          }
          resource="supplier_catalog_item"
        />
      </Box> */}
    </Box>
  )
}

export default FormData
