import React, { cloneElement, useMemo } from 'react'
import { Box } from '@material-ui/core'
import { FormWithRedirect, useEditController, EditContextProvider } from 'react-admin'
import FormData from './ProductCreateForm'
import { roundTo } from '../../../utils/utils'

const MyEdit = (props) => {
  // console.log('MYCPROPS', props)
  const createControllerProps = useEditController(props)
  const {
    basePath, // deduced from the location, useful for action buttons
    // defaultTitle, // the translated title based on the resource, e.g. 'Create Post'
    record, // empty object, unless some values were passed in the location state to prefill the form
    redirect, // the default redirection route. Defaults to 'edit', unless the resource has no edit view, in which case it's 'list'
    resource, // the resource name, deduced from the location. e.g. 'posts'
    save, // the create callback, to be passed to the underlying form as submit handler
    saving, // boolean that becomes true when the dataProvider is called to create the record
    version, // integer used by the refresh feature
  } = createControllerProps
  return (
    <EditContextProvider value={createControllerProps}>
      <div>
        {/* <h1>{defaultTitle}</h1> */}
        {cloneElement(props.children, {
          basePath,
          record,
          redirect,
          resource,
          save,
          saving,
          version,
        })}
      </div>
    </EditContextProvider>
  )
}

const ProductEdit = (props) => {
  return (
    <MyEdit {...props} actions={null} component="div" id={props.id} mutationMode="pessimistic">
      <ProductEditForm id={props.id} {...props} />
    </MyEdit>
  )
}

const ProductEditForm = (props) => {
  const {
    index,
    materials = [],
    // categories = [],
    imprints = [],
    packagingTypes = [],
    milestones = [],
    config = {},
    isManager = false,
    client_id,
    setProduct,
    // setProductData,
    removeProduct,
    sourcing_request_id,
    setClientQuoteProductData,
    srForm,
    record,
  } = props
  // console.log(props)

  const factoryPrices =
    record?.product_quotes?.length > 0 && record?.product_quotes[0].prices?.length > 0
      ? record?.product_quotes[0]?.prices?.map((item) => ({
          ...(item.id && { id: item.id }),
          unitPrice: roundTo(item.unit_price / 100, 2),
          production_lead_time: item.lead_time,
          quantity: item.quantity,
          landed_air: item.est_landed_cost_air / 100 || null,
          landed_sea: item.est_landed_cost_sea / 100 || null,
          totalCBM: item.total_cbm / 100 || null,
          shipmentWeight: item.shipment_weight / 100 || null,
          totalNumCartons: item.total_cartons,
          totalDuties: item.total_duties / 100 || null,
          freightCostPerPieceAir: item.freight_cost_air_per_piece / 100 || null,
          freightCostPerPieceSea: item.freight_cost_sea_per_piece / 100 || null,
          totalFreightCostAir: item.freight_cost_air / 100 || null,
          totalFreightCostSea: item.freight_cost_sea / 100 || null,
          containers: item.containers,
        }))
      : []

  const otherSpecifications = []

  const materialOptions =
    record?.product_options?.length > 0
      ? record.product_options?.filter(
          (spec) => !!spec.deleted_at === false && spec.option_value?.option?.name === 'Material',
        )
      : []

  const uniqueSpecs = []
  if (record?.product_specifications?.length > 0) {
    record.product_specifications.map((spec) => {
      if (uniqueSpecs.indexOf(spec.option_value.option.name) === -1) {
        uniqueSpecs.push(spec.option_value.option.name)
      }
    })
  }

  uniqueSpecs.map((spec) => {
    if (spec !== 'Colour' && spec !== 'Material') {
      otherSpecifications?.push({
        name: spec,
        option_value: {
          value:
            record?.product_specifications?.length > 0
              ? record.product_specifications
                  .filter(
                    (qspec) =>
                      qspec.option_value.option?.name === spec && qspec.deleted_at === null,
                  )
                  .map((item) => item.option_value?.value)
              : [],
        },
      })
    }
  })

  const oldSpecifications = []

  uniqueSpecs.map((spec) => {
    if (spec !== 'Colour' && spec !== 'Material') {
      oldSpecifications?.push({
        name: spec,
        values:
          record?.product_specifications?.length > 0
            ? record.product_specifications
                .filter(
                  (qspec) => qspec.option_value.option?.name === spec && qspec.deleted_at === null,
                )
                .map((val) => ({ id: val.id, value: val.option_value.value }))
            : [],
      })
    }
  })

  const productMaterials = record?.product_specifications?.filter(
    (spec) => !!spec.deleted_at === false && spec.option_value?.option?.name === 'Material',
  )

  const uniqueCustomSpecs = []
  record?.customisations?.map((spec) => {
    if (uniqueCustomSpecs.indexOf(spec.option_value.option.name) === -1) {
      uniqueCustomSpecs.push(spec.option_value.option.name)
    }
  })

  const productCustomisations = uniqueCustomSpecs.map((spec) => {
    return {
      name: spec,
      option_value: {
        value: record?.customisations
          ?.filter((qspec) => qspec.option_value.option?.name === spec && qspec.deleted_at === null)
          .map((item) => item.option_value?.value),
      },
    }
  })

  const oldCustomisations = uniqueCustomSpecs.map((spec) => {
    return {
      name: spec,
      values: record?.customisations?.filter(
        (qspec) => qspec.option_value.option?.name === spec && qspec.deleted_at === null,
      ),
    }
  })

  const categories = record?.product_categories
    ?.filter((item) => !item.deleted_at)
    .map((item) => item.category.id)

  const chosenTags = record?.product_tags?.map((item) => item.tag.id)

  const packagingOptions =
    record?.product_quotes?.length > 0 &&
    record?.product_quotes[0].packaging_options?.map((opt) => ({
      ...opt,
      costModifier: opt.cost_modifier === 0 ? 0 : opt.cost_modifier / 100 || null,
      cartonDimensions: {
        id: opt.carton_dimensions?.id,
        length: opt.carton_dimensions?.length / 100 || null,
        width: opt.carton_dimensions?.width / 100 || null,
        height: opt.carton_dimensions?.height / 100 || null,
        gross_weight: opt.carton_dimensions?.gross_weight / 100 || null,
      },
      packagingDimensions: {
        id: opt.packaging_dimensions?.id,
        length: opt.packaging_dimensions?.length / 100 || null,
        width: opt.packaging_dimensions?.width / 100 || null,
        height: opt.packaging_dimensions?.height / 100 || null,
        gross_weight: opt.packaging_dimensions?.gross_weight / 100 || null,
      },
      sizes: record.product_quotes[0].product_sizes?.map((sz) => ({
        ...sz,
        relId:
          opt.product_quote_product_size_packagings?.find(
            (el) => el.product_quote_product_size_id === sz.id,
          )?.id || null,
        active: opt.product_quote_product_size_packagings?.some(
          (el) => el.product_quote_product_size_id === sz.id,
        ),
        costModifier: sz.cost_modifier / 100,
      })),
      scmQuotePkgs: opt.scm_quote_packaging_options?.map((scmOpt) => scmOpt.id),
    }))

  const productSizes =
    record?.product_quotes?.length > 0 &&
    record.product_quotes[0].product_sizes.map((size) => ({
      ...size,
      costModifier: size.cost_modifier === 0 ? 0 : size.cost_modifier / 100 || null,
      productDimensions: {
        id: size.dimensions?.id / 100,
        length: size.dimensions?.length / 100 || null,
        width: size.dimensions?.width / 100 || null,
        height: size.dimensions?.height / 100 || null,
        gross_weight: size.dimensions?.gross_weight / 100 || null,
      },
      scmQuoteSizes: size.scm_quote_product_sizes?.map((scmSize) => scmSize.id),
    }))

  const configurations =
    record?.product_quotes?.length > 0 &&
    record.product_quotes[0].options?.map((opt) => ({
      ...opt,
      label: opt.name,
      max_unique_selections: opt.max_unique_selections,
      additional_cost_per_unique_choice: opt.additional_cost_per_unique_choice,
      additionalCostPerChoice: opt.additional_cost_per_unique_choice / 100 || 0,
      values: opt.values?.map((val) => ({
        ...val,
        setupCost: val.setup / 100 || 0,
        unitCost: val.cost / 100 || 0,
        components: record.product_quotes[0].components?.map((cmp) => {
          // console.log(val, cmp)
          return {
            ...cmp,
            active: val.components?.some((el) => el.component_id === cmp.id),
            relId: val.components?.find((el) => el.component_id === cmp.id)?.id || null,
          }
        }),
      })),
    }))

  const productDecorations =
    record?.product_quotes?.length > 0 &&
    record.product_quotes[0].decorations?.map((el) => ({
      ...el,
      unitCost: el.cost / 100 || 0,
    }))

  // console.log('record', record)

  const init = {
    ...record,
    productDecorations,
    configurations,
    packagingOptions,
    productSizes,
    productCustomisations,
    additional_files: [],
    client_id,
    dutyRate: record?.duty_rate === 0 ? 0 : record?.duty_rate / 100 || null,
    toolingCost: record?.product_quotes
      ? record?.product_quotes[0]?.tooling_cost === 0
        ? 0
        : record?.product_quotes[0]?.tooling_cost / 100 || null
      : null,
    sampleCost: record?.product_quotes
      ? record?.product_quotes[0]?.sample_cost === 0
        ? 0
        : record?.product_quotes[0]?.sample_cost / 100 || null
      : null,
    customSampleCost: record?.product_quotes
      ? record?.product_quotes[0]?.custom_sample_cost === 0
        ? 0
        : record?.product_quotes[0]?.custom_sample_cost / 100 || null
      : null,
    factoryPrices,
    launch_date:
      record?.product_quotes?.length > 0 && record?.product_quotes[0].scm_quotes?.length > 0
        ? record?.product_quotes[0]?.scm_quotes[0]?.launch_date
        : null,
    quote_notes:
      record?.product_quotes?.length > 0 && record?.product_quotes[0].scm_quotes?.length > 0
        ? record?.product_quotes[0]?.scm_quotes[0]?.notes
        : null,
    clientSamplePrice:
      record?.product_quotes?.length > 0 && record?.product_quotes[0].scm_quotes?.length > 0
        ? record?.product_quotes[0]?.scm_quotes[0]?.client_sample_price
        : null,
    categories: categories || [],
    tags: chosenTags || [],
    productMaterials: productMaterials || [],
    otherSpecifications: otherSpecifications || [],
    oldSpecifications: oldSpecifications || [],
    materialOptions: materialOptions || [],
    oldCustomisations: oldCustomisations || [],
    containerRate20ft:
      record?.product_quotes[0].container_rate_20ft === 0
        ? 0
        : record?.product_quotes[0].container_rate_20ft / 100 ||
          config.estimated_container_rate_20ft / 100 ||
          null,
    containerRate40ft:
      record?.product_quotes[0].container_rate_40ft === 0
        ? 0
        : record?.product_quotes[0].container_rate_40ft / 100 ||
          config.estimated_container_rate_40ft / 100 ||
          null,
    containerRate40ftHC:
      record?.product_quotes[0].container_rate_40ft_hc === 0
        ? 0
        : record?.product_quotes[0].container_rate_40ft_hc / 100 ||
          config.estimated_container_rate_40ft_hc / 100 ||
          null,
    freightRateSea:
      record?.product_quotes[0].freight_rate_sea === 0
        ? 0
        : record?.product_quotes[0].freight_rate_sea / 100 ||
          config.estimated_freight_rate_sea / 100 ||
          null,
    freightRateAir:
      record?.product_quotes[0].freight_rate_air === 0
        ? 0
        : record?.product_quotes[0].freight_rate_air / 100 ||
          config.estimated_freight_rate_air / 100 ||
          null,
    does_sea_freight_include_duties:
      record?.product_quotes[0].does_sea_freight_include_duties ||
      config.estimated_freight_rate_sea_duties_included === 'true' ||
      false,
    // additional_files_type,
  }

  // console.log('init', init)

  const initVals = useMemo(() => init, [init])

  if (!record) return null

  const validation = (values) => {
    const errors = {}

    // if (values?.catagories?.length < 1 && values?.product_categories?.length < 1) {
    //   errors.categories = 'Min 1 category'
    // }

    !values.name ? (errors.name = 'Name') : null
    // !values.short_description ? (errors.short_description = 'Short Description') : null
    !values.hts_code ? (errors.hts_code = 'HTS Code') : null
    values.product_quotes?.length > 0 && !values.product_quotes[0].included_packaging_option_id
      ? (errors.included_packaging_option_id = 'Included Packaging Option')
      : null

    // !values.product_dimensions?.length && !values.productDimensions?.length
    //   ? (errors.productDimensions = { length: 'Product Length' })
    //   : null
    // !values.product_dimensions?.width && !values.productDimensions?.width
    //   ? (errors.productDimensions = { width: 'Product Width' })
    //   : null
    // !values.product_dimensions?.height && !values.productDimensions?.height
    //   ? (errors.productDimensions = { height: 'Product Height' })
    //   : null
    // !values.carton_dimensions?.length && !values.cartonDimensions?.length
    //   ? (errors.cartonDimensions = { length: 'Carton Length' })
    //   : null
    // !values.carton_dimensions?.width && !values.cartonDimensions?.width
    //   ? (errors.cartonDimensions = { width: 'Carton Width' })
    //   : null
    // !values.carton_dimensions?.height && !values.cartonDimensions?.height
    //   ? (errors.cartonDimensions = { height: 'Carton Height' })
    //   : null
    // !values.carton_dimensions?.gross_weight && !values.cartonDimensions?.gross_weight
    //   ? (errors.cartonDimensions = { gross_weight: 'Carton Weight' })
    //   : null

    // values.product_quotes[0].included_packaging_option_id
    //   ? errors.included_packaging_option_id === 'Included Pkg Option'
    //   : null

    values.factoryPrices.length < 1 ? (errors.prices = 'Min 1 Price') : null
    !values.product_quotes[0].supplier_id ? (errors.supplier_id = 'Supplier') : null
    // !values.product_quotes[0].packaging_type ? (errors.packaging_type = 'Packaging Type') : null
    // !values.product_quotes[0].pieces_per_carton
    //   ? (errors.pieces_per_carton = 'Pieces per Carton')
    //   : null

    return errors
  }

  return (
    <FormWithRedirect
      {...props}
      validate={validation}
      initialValues={initVals}
      render={(formProps) => (
        <Box>
          <FormData
            // categories={categories}
            packagingTypes={packagingTypes}
            materials={materials}
            imprints={imprints}
            milestones={milestones}
            config={config}
            client_id={client_id}
            isManager={isManager}
            productData={record}
            removeProduct={removeProduct}
            setClientQuoteProductData={setClientQuoteProductData}
            setProduct={setProduct}
            // setProductData={setProductData}
            index={index}
            sourcing_request_id={sourcing_request_id}
            formProps={formProps}
            srForm={srForm}
          />
        </Box>
      )}
    />
  )
}

export default ProductEdit
