import React, { useEffect } from 'react'
import { Box, Button, Tooltip } from '@material-ui/core'
import {
  required,
  DateInput,
  SimpleFormIterator,
  ArrayInput,
  FormDataConsumer,
  ReferenceInput,
  SelectInput,
  TextInput,
} from 'react-admin'
import { useDropzone } from 'react-dropzone'
import { useFormState, useForm } from 'react-final-form'
import { uniqueId } from 'lodash'
import moment from 'moment'
import CancelIcon from '@material-ui/icons/Cancel'
import IconButton from '@material-ui/core/IconButton'
import WarningIcon from '@material-ui/icons/Warning'
import AddIcon from '@material-ui/icons/Add'
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline'
import ChipInput from '../../../components/input/ChipInput'
import NumberFormat from '../../components/CustomNumberFormat'
import ClientSelectWithCreate from '../../components/ClientSelectWithCreate'
import { getDisplayAddress, getDisplayName } from '../../../utils/utils'
import useStyles from '../styles'

const FormData = () => {
  const classes = useStyles()
  const form = useForm()

  useEffect(() => {
    return () => {}
  }, [])

  const { values, errors } = useFormState()

  // Object.keys(errors).length > 0 && console.log('FORMERRORS', errors)

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*'],
    onDrop: (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        // console.log(acceptedFiles)
        // console.log('changing')
        form.change('images', [
          ...(values.images || []),
          ...acceptedFiles.map((file) => {
            return {
              id: uniqueId(),
              type: 'PHOTO',
              file: Object.assign(file, {
                url: URL.createObjectURL(file),
              }),
            }
          }),
        ])
        if (!values.reference_photo) {
          // console.log('changing')
          form.change('reference_photo', acceptedFiles[0])
        }
      }
    },
  })

  useEffect(() => {
    // Make sure to revoke the data uris to avoid memory leaks, will run on unmount
    return () => values.images?.forEach((file) => URL.revokeObjectURL(file.url))
  }, [])

  const previews = values.images
    ?.filter((item) => !!item.deleted_at === false)
    .map((file) => {
      const currentTime = moment().toISOString()

      return (
        <Box key={`preview${file.file?.name || file.id}`} className={classes.previewImageBox}>
          <img
            src={file.file?.url}
            className={classes.previewImage}
            onClick={() => {
              // console.log('changing')
              form.change('reference_photo', file.file)
            }}
          />
          <IconButton
            aria-label="delete"
            className={classes.deleteBtn}
            onClick={() => {
              const image = values.images.findIndex((item) => item.id === file.id)
              // console.log('changing')
              form.change(`images[${image}].deleted_at`, currentTime)
            }}
          >
            <CancelIcon />
          </IconButton>
        </Box>
      )
    })

  return (
    <Box className={classes.outerBox}>
      <Box>
        <div {...getRootProps({ className: classes.imageDropZone })}>
          <input {...getInputProps()} />
          {values.reference_photo ? (
            <img src={values.reference_photo.url} alt="alt" className={classes.primaryPhoto} />
          ) : null}

          {!values.reference_photo ? (
            <span>{`Drag & drop some files here, or click to select files`}</span>
          ) : null}
        </div>
        <Box className={classes.previewsContainer}>{previews}</Box>
      </Box>

      <TextInput
        variant="standard"
        fullWidth
        className={classes.selectBoxWidth}
        inputProps={{
          maxLength: 50,
        }}
        source="name"
        label="Product Name:"
      />
      <ReferenceInput
        source="sca_id"
        reference="users"
        label="SCA"
        // sort={{ field: 'id', order: 'ASC' }}
        filter={{ roles: { role: { code: { _in: ['sca', 'sca-manager'] } } } }}
        // style={{ minWidth: '200px' }}
        alwaysOn
        variant="standard"
      >
        <SelectInput optionText={(val) => getDisplayName(val)} fullWidth />
      </ReferenceInput>
      <ClientSelectWithCreate className={classes.selectBoxWidth} />

      <DateInput
        source="required_date"
        label="Required In-hands Date"
        fullWidth
        variant="standard"
        margin="none"
        className={classes.fieldInput}
      />
      <NumberFormat
        className={classes.fieldInput}
        displayType="input"
        thousandSeparator
        decimalScale={2}
        fixedDecimalScale
        isNumericString
        // validate={required()}
        source="targetPrice"
        label="Target Price"
        prefix="$ "
      />
      <NumberFormat
        className={classes.fieldInput}
        displayType="input"
        thousandSeparator
        decimalScale={0}
        fixedDecimalScale
        isNumericString
        // validate={required()}
        source="target_quantity_min"
        label="Minimum Target Quantity"
      />
      <NumberFormat
        className={classes.fieldInput}
        displayType="input"
        thousandSeparator
        decimalScale={0}
        fixedDecimalScale
        isNumericString
        // validate={required()}
        source="target_quantity_max"
        label="Maximum Target Quantity"
      />
      <Box mt="10px">
        <ArrayInput source="product_criteria" label="Product Criteria">
          <SimpleFormIterator
            TransitionProps={{ enter: false, exit: false }}
            addButton={
              <Button style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}>
                <AddIcon />
              </Button>
            }
            removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
          >
            <FormDataConsumer>
              {({ getSource, scopedFormData, rest }) => (
                <Box display="flex">
                  <TextInput
                    source={getSource('criteria')}
                    {...rest}
                    record={scopedFormData}
                    label="Criteria"
                    fullWidth
                    variant="standard"
                    validate={required()}
                    margin="none"
                    className={`${classes.fieldInputNoPadding}`}
                  />
                  <ChipInput
                    source={getSource('value')}
                    className={`${classes.chipInputNoPadding}`}
                    label="Values"
                    variant="standard"
                    margin="none"
                    {...rest}
                    record={scopedFormData}
                  />
                </Box>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
      <Box mt="10px">
        <ArrayInput source="packaging_criteria" label="Packaging Criteria">
          <SimpleFormIterator
            TransitionProps={{ enter: false, exit: false }}
            addButton={
              <Button style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}>
                <AddIcon />
              </Button>
            }
            removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
          >
            <FormDataConsumer>
              {({ getSource, scopedFormData, rest }) => (
                <Box display="flex">
                  <TextInput
                    source={getSource('criteria')}
                    {...rest}
                    record={scopedFormData}
                    label="Criteria"
                    fullWidth
                    variant="standard"
                    validate={required()}
                    margin="none"
                    className={`${classes.fieldInputNoPadding}`}
                  />
                  <ChipInput
                    source={getSource('value')}
                    className={`${classes.chipInputNoPadding}`}
                    label="Values"
                    variant="standard"
                    margin="none"
                    {...rest}
                    record={scopedFormData}
                  />
                </Box>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
      {values.client_id && (
        <ReferenceInput
          source="delivery_address_id"
          reference="address"
          sort={{ field: 'id', order: 'ASC' }}
          filter={{ organisation_id: values.client_id }}
          perPage={100}
          alwaysOn
          style={{ minWidth: '200px' }}
          variant="standard"
        >
          <SelectInput optionText={(record) => getDisplayAddress(record)} fullWidth />
        </ReferenceInput>
      )}
      <TextInput
        variant="standard"
        fullWidth
        className={classes.selectBoxWidth}
        inputProps={{
          maxLength: 50,
        }}
        source="destination_post_code"
        label="Destination Post Code:"
      />
      <SelectInput
        source="market"
        label="Product Market:"
        choices={[
          {
            name: 'eCommerce',
          },
          {
            name: 'Special Gift',
          },
          {
            name: 'Internal Use',
          },
          {
            name: 'Wholesale',
          },
          {
            name: 'Giveaway/Promotion',
          },
          {
            name: 'Retail',
          },
        ]}
        optionText="name"
        optionValue="name"
        defaultValue=""
        // validate={required()}
        fullWidth
        variant="standard"
        className={classes.dialogInput}
      />
      <Box mt="10px">
        <ArrayInput source="references" label="References">
          <SimpleFormIterator
            TransitionProps={{ enter: false, exit: false }}
            addButton={
              <Button style={{ width: '120px', backgroundColor: '#F8F8FA', color: 'black' }}>
                <AddIcon />
              </Button>
            }
            removeButton={<DeleteOutlineIcon style={{ cursor: 'pointer', marginTop: '-25px' }} />}
          >
            <FormDataConsumer>
              {({ getSource, scopedFormData, rest }) => (
                <Box display="flex">
                  <TextInput
                    source={getSource('url')}
                    {...rest}
                    record={scopedFormData}
                    label="Website"
                    fullWidth
                    variant="standard"
                    margin="none"
                    className={`${classes.fieldInput}`}
                  />
                  <TextInput
                    source={getSource('brand')}
                    {...rest}
                    record={scopedFormData}
                    label="Brand"
                    fullWidth
                    variant="standard"
                    margin="none"
                    style={{ marginLeft: '10px' }}
                    className={`${classes.fieldInput}`}
                  />
                  <TextInput
                    source={getSource('reason')}
                    {...rest}
                    record={scopedFormData}
                    label="Reason"
                    fullWidth
                    variant="standard"
                    validate={required()}
                    margin="none"
                    style={{ marginLeft: '10px' }}
                    className={`${classes.fieldInput}`}
                  />
                </Box>
              )}
            </FormDataConsumer>
          </SimpleFormIterator>
        </ArrayInput>
      </Box>
      <TextInput
        variant="standard"
        multiline
        minRows={2}
        inputProps={{
          maxLength: 250,
          style: {
            marginBottom: 15,
            alignItems: 'baseline',
            minWidth: '460px',
          },
        }}
        source="notes"
        label="Additional Notes:"
      />
      <div style={{ textAlign: 'center' }}>
        {Object.values(errors)?.length > 0 ? (
          <Tooltip title={Object.values(errors).join(', ')}>
            <WarningIcon style={{ marginTop: '10px', marginLeft: '20px' }} />
          </Tooltip>
        ) : null}
      </div>
    </Box>
  )
}

export default FormData
