import React, { useState } from 'react'
import { useForm, useFormState } from 'react-final-form'
import { useNotify, useDataProvider } from 'react-admin'
import moment from 'moment'
import { get } from 'lodash'
import { useDropzone } from 'react-dropzone'
import { Box, CircularProgress, IconButton } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import CancelIcon from '@material-ui/icons/Cancel'
import ZoomInIcon from '@material-ui/icons/ZoomIn'
import uploadFile from '../../../dataProvider/aws_upload'

const useStyles = makeStyles({
  primaryPhoto: {
    // border: 'dashed gray 2px',
    width: '85px',
    height: '85px',
    position: 'absolute',
    zIndex: '-1',
  },
  primaryPhotoZoomContainer: {
    border: 'solid gray 1vw',
    borderRadius: '1vw',
    maxWidth: '65vw',
    // maxHeight: '50vh',
    position: 'absolute',
    backgroundColor: 'white',
    top: '0px',
    left: '0px',
    // minWidth: '50vw',
    // minHeight: '50vh',
    zIndex: '1000',
  },
  primaryPhotoZoom: {
    maxWidth: '64vw',
  },
  previewsContainer: {
    display: 'flex',
    overflowX: 'scroll',
    width: '400px',
    // margin: '0px 0px 5px',
  },
  previewImageBox: {
    margin: '2px',
    width: '85px',
    height: '85px',
    minWidth: '85px',
    minHeight: '85px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '2px',
    position: 'relative',
  },
  previewImage: {
    '&:hover': {
      border: 'gray solid 2px',
    },
    zIndex: '100',
    margin: '2px',
    maxWidth: '80px',
    maxHeight: '80px',
    cursor: 'pointer',
  },
  previewNewImage: {
    zIndex: '0',
    margin: '2px',
    maxWidth: '80px',
    maxHeight: '80px',
    opacity: '0.4',
    transition: ['background', 'color', 'font-size'],
    transitionDuration: 2,
    transitionDelay: '1s',
  },
  deleteBtn: {
    position: 'absolute',
    top: '0px',
    left: '45px',
    zIndex: '100',
  },
  zoomBtn: {
    position: 'absolute',
    top: '0px',
    left: '5px',
    zIndex: '100',
  },
  imageDropZone: {
    width: '85px',
    height: '85px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    opacity: '0.9',
    border: 'dashed gray 2px',
    cursor: 'pointer',
    textAlign: 'center',
    fontSize: '12px',
  },
})

const Photos = ({
  id,
  resource,
  onSave,
  field,
  onClickPreview,
  //  primaryField,
  reference,
  type,
}) => {
  const classes = useStyles()
  const form = useForm()
  const dataProvider = useDataProvider()
  const notify = useNotify()
  const { values } = useFormState()
  const [uploading, setUploading] = useState(false)
  // const [progress, setProgress] = useState(0)
  const [newImages, setNewImages] = useState([])
  const [zoomOpen, setZoomOpen] = useState(null)

  // const primaryPhoto = get(values, primaryField)
  const previewFiles = get(values, field)
  // console.log(values[primaryField])
  // console.log(primaryPhoto)
  // console.log(previewFiles)

  // console.log(resource, field, onClickPreview, reference, type, id)

  // console.log(uploading, progress, newImages)

  const previews = previewFiles
    ?.filter((item) => !!item.deleted_at === false)
    .map((file) => {
      const currentTime = moment().toISOString()

      return (
        <Box key={`preview${file.file?.name || file.id}`}>
          <Box className={classes.previewImageBox}>
            <img
              src={file.file?.url}
              className={classes.previewImage}
              onClick={async () => {
                try {
                  onClickPreview && (await onClickPreview(file))

                  notify('Update Complete', { type: 'info' })
                } catch (error) {
                  console.log(error)
                  notify(error.message, { type: 'warning' })
                }
              }}
            />
            <IconButton
              aria-label="delete"
              className={classes.deleteBtn}
              onClick={async () => {
                // console.log(previewFiles, file.id)
                const image = previewFiles.findIndex((item) => item.id === file.id)
                try {
                  await dataProvider.delete(resource, {
                    data: { id: file.id },
                  })
                  form.change(`${field}[${image}].deleted_at`, currentTime)
                  notify('Image Deleted', { type: 'info' })
                } catch (error) {
                  notify(error.message, { type: 'warning' })
                }
              }}
            >
              <CancelIcon />
            </IconButton>
            <IconButton
              aria-label="zoomin"
              className={classes.zoomBtn}
              onClick={async () => {
                setZoomOpen(file.file?.url)
              }}
            >
              <ZoomInIcon />
            </IconButton>
          </Box>
        </Box>
      )
    })

  const { getRootProps, getInputProps } = useDropzone({
    accept: ['image/*'],
    onDrop: async (acceptedFiles) => {
      if (acceptedFiles && acceptedFiles.length > 0) {
        // console.log(acceptedFiles)
        setUploading(true)
        const files = []

        await Promise.all(
          acceptedFiles.map(async (photo) => {
            const file = await uploadFile(photo)

            // console.log(file)

            setNewImages((prevState) => {
              return [...prevState, { name: photo.name, url: file }]
            })

            files.push({
              [reference]: id,
              type,
              file: {
                data: {
                  url: file,
                  filename: photo.name || photo.path,
                },
              },
            })
          }),
        )

        // console.log(files)

        try {
          const { data } = await dataProvider.createMany(resource, {
            objects: files,
          })

          // console.log('RESP', data)
          form.change(field, [...(previewFiles || []), ...(data || [])])
          setNewImages([])

          onSave && (await onSave(data))

          setUploading(false)
          // setProgress(0)

          notify('Upload Complete', { type: 'info' })
        } catch (error) {
          console.log(error)
          notify(error.message, { type: 'warning' })
          setNewImages([])
          setUploading(false)
        }
      }
    },
  })

  return (
    <Box display="flex">
      {zoomOpen ? (
        <Box className={classes.primaryPhotoZoomContainer}>
          <img
            src={zoomOpen}
            className={classes.primaryPhotoZoom}
            onClick={async () => {
              setZoomOpen(null)
            }}
          />
        </Box>
      ) : null}
      {/* <Typography
        aria-owns={open ? 'mouse-over-popover' : undefined}
        aria-haspopup="true"
      >
        Hover with a Popover.
      </Typography> */}
      <div {...getRootProps({ className: classes.imageDropZone })}>
        <input {...getInputProps()} />
        {/* {primaryPhoto ? (
          <img src={primaryPhoto.url} alt="" className={classes.primaryPhoto} />
        ) : null} */}

        {/* {!primaryPhoto ? ( */}
        <span>{`Drag & drop some files here, or click to select files`}</span>
        {/* ) : null} */}
      </div>
      <Box className={classes.previewsContainer} alignItems="center">
        {previews}
        {newImages.map((file) => (
          <img key={`preview${file.name}`} src={file.url} className={classes.previewNewImage} />
        ))}
        {uploading && <CircularProgress style={{ width: '20px', height: '20px' }} />}
      </Box>
    </Box>
  )
}

export default Photos
